.privacy-policy,
.terms {
  background: url("../../assets/images/home/bg.png") #0e0d27;
  width: 100%;
  background-size: cover;
  height: 100%;
  padding-top: 85px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position: center;
}
.legal-content-body {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.legal-page-heading {
  margin-bottom: 60px;
}
.legal-page-heading h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
}
.legal-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.legal-subheading h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
}
.legal-subheading span {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #2e8be6;
  padding-right: 10px;
}
.legal-paragraph {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.legal-paragraph p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #aca2b4;
  margin: 0;
}
.legal-paragraph h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
}
.legal-paragraph ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
}
.legal-paragraph li {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #aca2b4;
}
.legal-paragraph li::marker {
  color: #2e8be6;
}
