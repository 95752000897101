.admin-menu {
  background: #120f27;
  max-width: 270px;
  width: 100%;
  position: fixed;
  height: calc(100vh - 70px);
  overflow: auto;
  padding-bottom: 30px;
}

.admin-menu::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.admin-menu::-webkit-scrollbar-track {
  background: rgb(170 170 170 / 20%);
}

.admin-menu::-webkit-scrollbar-thumb {
  background-color: #2E8BE6;
}

.menu-list {
  padding-top: 15px;
}

.menu-list ul {
  margin: 0;
  padding: 0;
}

.menu-list ul a {
  padding: 15px 15px;
  padding-left: 30px;
  display: flex;
  gap: 10px;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.menu-list ul a:hover {
  color: #e80054 !important;
}

.menu-list ul a.active {
  color: #e80054 !important;
}
.menu-list ul a.active::before {
  content: "";
  background: #e80054;
  width: 2px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 20px;
}

.menu-list ul a:hover svg path,
.menu-list ul a:hover svg circle,
.menu-list ul a:hover svg rect,
.menu-list ul a.active svg path,
.menu-list ul a.active svg circle,
.menu-list ul a.active svg rect {
  stroke: #e80054;
}

/* ********************** Social card *********************** */
.social-card-content {
  max-width: 210px;
  width: 100%;
  margin: 0 auto;
  background: #28285d;
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin-top: 62px;
}
.social-card-heading {
  padding-top: 30px;
}
.social-card-heading h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.003em;
  text-transform: uppercase;
  max-width: 165px;
  width: 100%;
  margin: 0 auto;
}
.social-card-share {
  padding-top: 15px;
  text-align: center;
  padding-bottom: 21px;
}

.social-card-copy p {
  font-weight: 300;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
  margin: 0;
  padding-bottom: 5px;
}

.social-card-share p {
  font-weight: 300;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
  margin: 0;
  padding-bottom: 9px;
}
.social-cards-share {
  padding-top: 15px;
}

.social-share {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.social-card-input {
  position: relative;
}

.social-card-input img {
  position: absolute;
  right: 30px;
  top: 10px;
}

.social-card-input input {
  /* opacity: 0.3; */
  border: 1px solid #6e6e7d;
  border-radius: 6px;
  background: transparent;
  max-width: 170px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: #ffffff;
}
.social-card-input input.form-control:focus {
  background: #28285d;
  color: #fff;
}
.backsite-btn a {
  display: block;
  max-width: 210px;
  width: 100%;
  height: 50px;
  margin: 0 auto;
  padding: 10px;
  background: rgba(232, 0, 84, 0.1);
  border: 2px solid #e80054;
  box-shadow: 0px 19px 30px -5px rgb(232 0 84 / 25%);
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}
.backsite-btn a:hover {
  box-shadow: 0px 19px 30px -5px rgb(232 0 84 / 50%);
}

.backsite-btn {
  text-align: center;
  margin-top: 50px;
}
.backsite-btn button:hover,
.backsite-btn button:focus,
.backsite-btn button:active {
  background: rgba(232, 0, 84, 0.1);
  border: 2px solid #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  color: #ffffff;
}
.backsite-btn button.btn:active:focus {
  box-shadow: none;
}
/* ******************** wallet menu *********************** */
.wallet-balance-menu {
  max-width: 285px;
  width: 100%;
  background: #21244e;
  min-height: 100vh;
}
.wallet-balance {
  padding: 35px 30px 30px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.wallet-balance h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.wallet-balance-coin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 23px 0 10px;
}

.wallet-balance-coin p {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding-left: 10px;
}

.wallet-balance-coin-convert span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.003em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.3;
  display: block;
  padding-top: 10px;
}
.wallet-balance-bitcoin p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.wallet-balance-bitcoin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.wallet-balance-bitcoin span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.003em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.7;
}
.wallet-balance-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.wallet-balance-btn button {
  width: 103px;
  height: 40px;
}

.wallet-balance-btn button.red-btn,
.wallet-balance-btn button.red-btn:focus {
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
}
.wallet-balance-btn button.red-btn:hover {
  box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.50);
}
.wallet-balance-btn button.blue-btn,
.wallet-balance-btn button.blue-btn:focus {
  background: linear-gradient(92.5deg, #1459ad 0.69%, #0f2faa 101.34%);
  box-shadow: 0px 10px 20px -5px rgb(0 9 232 / 25%);
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
}
.wallet-balance-btn button.blue-btn:hover {
  box-shadow: 0px 10px 20px -5px rgba(0, 232, 23, 0.25);
}
.wallet-balance-btn button.green-btn,
.wallet-balance-btn button.green-btn:focus {
  background:     linear-gradient(92.5deg, #0e9375 0.69%, #03821d 101.34%);
    box-shadow: 0px 10px 20px -5px rgb(12 143 94 / 48%);
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
}
.wallet-balance-btn button.green-btn:hover {
  box-shadow: 0px 10px 20px -5px rgb(0 232 54 / 50%);
}
.wallet-balance-btn button.white-btn,
.wallet-balance-btn button.white-btn:focus {
  background: #ffffff;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #e80054;
  border: none;
  box-shadow: none;
}
.wallet-balance-btn button.white-btn:hover {
  background: rgb(255 255 255 / 90%);
}

.wallet-transaction-recent {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.wallet-transaction-recent p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}

.wallet-transaction-recent span {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.4;
}
/* .wallet-transaction {
  padding: 30px 18px 30px 30px;
} */
.wallet-transaction-card {
  padding: 0 18px 30px 30px;
}

.wallet-transaction-card-scroll {
  overflow-y: scroll;
  max-height: 740px;
  /* height: calc(100vh - 520px); */
  padding-right: 12px;
}
.wallet-transaction-card-scroll::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #21244e;
  height: fit-content;
}

.wallet-transaction-card-scroll::-webkit-scrollbar {
  width: 2px;
  background-color: #21244e;
  border-radius: 4px;
}

.wallet-transaction-card-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  height: 38px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
  background-color: rgba(46, 139, 230, 1);
  transition: all 0.5s;
}
.wallet-transaction-recent {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 30px 30px 20px;
}

.wallet-transaction-recent p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  color: #ffffff;
  margin: 0;
}

.wallet-transaction-recent span {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  color: #ffffff;
  opacity: 0.4;
}

.transaction-green-card {
  background: #2d3157;
  box-shadow: 0px 14px 30px -6px rgba(18, 19, 35, 0.3);
  border-radius: 10px;
  padding: 17px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-card {
  display: flex;
  align-items: center;
  background: #2d3157;
  box-shadow: 0px 14px 30px -6px rgba(18, 19, 35, 0.3);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 17px 12px;
}
.transaction-card-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 8px;
}

.transaction-card-left p {
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0;
  padding-bottom: 7px;
}

.transaction-card-left span {
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;
  opacity: 0.4;
  display: block;
}

.transaction-card-right p {
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: #2e8be6;
  margin: 0;
  padding-bottom: 2px;
}

.transaction-card-right span {
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  text-align: right;
  color: #ffffff;
  display: block;
}
