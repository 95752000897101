.login-form {
  margin: 0px auto;
  padding: 0px 0px;
  position: relative;
}

.login-form img {
  max-width: 250px;
  margin: 0px auto;
  display: block;
}
.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid#018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.login-form h1 {
  font-size: 32px;
  text-align: center;
  color: #fff;
}

.login-form p {
  text-align: center;
  color: #99a4b0cc;
  font-size: 14px;
}

.login-form h1 span {
  color: #E80054;
}

.login-box {
  padding-top: 40px;
  max-width: 420px;
  margin: 0px auto;
}
.login-box .form-label{
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.login-box .form-group .form-control {
  height: auto;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7C748E;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
}

.login-box .form-group .form-control::placeholder {
  color: #FFFFFF;
}

.login-box .form-group .form-control:focus {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.05);
}

.login-box input:-internal-autofill-selected {
  background: rgba(255, 255, 255, 0.05) !important;
}
.login-box .form-group .form-control:-webkit-autofill{
  box-shadow: none;
}

.login-box .form-group {
  margin-bottom: 20px;
  position: relative;
}
.login-box .form-group .input-icon{
  color: #7C748E;
}

.l-btn.btn.btn-primary {
  padding: 20px 40px;
  background: #E80054;
  box-shadow: 0px 19px 30px -5px rgb(232 0 84 / 25%);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #FFFFFF;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 40px;
}

.l-btn.btn.btn-primary:hover,
.l-btn.btn.btn-primary:focus,
.l-btn.btn.btn-primary:active {
  background: #E80054;
  box-shadow: none;
  border: none;
}

.login-button {
  text-align: center;
}

.login-box p {
  text-align: center;
  padding-top: 20px;
  font-size: 14px;
  color: #99a4b0cc;
  margin: 0;
}

.signup-link {
  color: #E80054;
  text-decoration: underline;
}

.signup-link:hover {
  color: #c5c5c5;
}

.forget-link {
  color: #E80054;
  font-size: 13px;
}

.forget-link:hover {
  color: #99a4b0cc;
  text-decoration: none;
}

.forget-password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}
.forget-password a{
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #FFFFFF;
  text-decoration: none;
}
.forget-password a:hover{
  color: #E80054;
}
.remember-me .form-check-input[type=checkbox]{
  width: 20px;
  height: 20px;
  border: 1px solid #79748E;
  border-radius: 4px;
  background: transparent;
}

.remember-me .form-check-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: rgb(255 255 255 / 80%);
  padding-left: 10px;
}
.remember-me .form-check-input:focus{
  box-shadow: none;
}
.remember-me  .form-check-input:checked[type=checkbox]{
  background: url("../../assets/images/header/checkTick.svg");
  width: 20px;
  height: 20px;
  background-position: center;
  border: none;
}

.social-login {
  text-align: center;
}

.social-login span {
  color: #afb4d0;
  display: block;
  padding: 10px 0px;
  font-size: 12px;
}

.social-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social-btns .btn {
  width: 48%;
  font-size: 14px;
}

.facebook-btn {
  background: #3b5998 !important;
  border-color: #3b5998 !important;
}

.twitch-btn {
  background: #4b367c !important;
  border-color: #4b367c !important;
}

.login {
  position: relative;
}

.login-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.login-popup .form-label {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px;
}

.login-popup .modal-header .close:focus {
  outline: none;
}

.login-popup .close {
  color: #99a4b0cc;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
  cursor: pointer;
}

.login-popup .close:hover {
  color: #efebeb;
}

.login-popup .modal-body {
  padding-top: 0;
}

.login-popup .modal-content {
  position: relative;
  background: #1e2024;
  background-size: cover;
  background-position: center;
}


.login-popup .modal-dialog{
  max-width: 350px;
}

.btn-close{
  filter: invert(1);
}
.btn-close:focus{
  box-shadow: none!important;

}

.forget-link,
.forget-link:focus,
.forget-link:hover {
  background: transparent !important;
  border: none !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #E80054 !important;
  box-shadow: none !important;
  padding: 0 !important;
  padding-left: 10px !important;
  letter-spacing: 0.003em;
}

.forget-link.btn-primary:not(:disabled):not(.disabled).active:focus,
.forget-link.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important;
}
