.admin-content-wrapper {
    padding: 30px 0;
}
.admin-section-title{
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.admin-section-title h3{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
    text-transform: capitalize;
}
.admin-section-title  button{
    background: #E80054;
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    height: 60px;
    gap: 10px;
    max-width: 262px;
    width: 100%;
    border: none;
    padding: 20px;
}
.admin-section-title  button:hover{
    background: #E80054;
    border: none;
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.50);
}
.admin-section-title  button:focus, .admin-section-title  .btn:active:focus, .admin-section-title  .btn:active{
    background: #E80054;
    border: none;
    box-shadow: none;
}
.admin-tab-section ul.nav.nav-tabs {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.admin-tab-section .nav-tabs .nav-link {
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-transform: uppercase;
    color: #675C7B;
    border: none;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
}

.admin-tab-section .nav-tabs li {
    margin-right: 40px;
}

.admin-tab-section .nav-tabs li:last-child {
    margin: 0;
}

.admin-tab-section .nav-tabs .nav-item.show .nav-link,
.admin-tab-section .nav-tabs .nav-link.active {
    color: #E80054;
    background: none;
    border-bottom: 2px solid #E80054;
}
.admin-table .table-responsive {
    padding: 1px;
    background: rgb(255 255 255 / 10%);
    border-radius: 10px;
    margin: 0;
}
.admin-table .table{
    margin: 0;
}
.admin-table  .table>thead{
    background: rgb(22 17 48 / 90%);
    border-radius: 9px 9px 0px 0px;
}

.admin-table .table thead th{
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgb(255 255 255 / 50%);
    border: none;
    padding: 18px 10px 21px 30px ;
}
.admin-table .table thead th:first-child{
    border-radius: 9px 0 0 ;
}
.admin-table .table thead th:last-child{
    border-radius: 0  9px 0 0 ;
}
.admin-table .table tbody tr:hover{
    background: rgb(46 139 230 / 10%);
}
.admin-table .table tbody td {
    border: none;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.003em;
    padding: 15px 10px 15px 30px;
    white-space: nowrap;
    border-bottom: 1px solid #1F1842;
    vertical-align: middle;
}

.admin-table .table tbody tr:last-child td{
    border-bottom: none;
}
.transaction-image img{
    max-width: 70px;
    height: 16px;
}
.green-status-text span{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.003em;
    color: #58C581;
    position: relative;
    padding-left: 7px;
}
.green-status-text span:before{
    content: "";
    position: absolute;
    background: #58C581;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    top: 6px;
    left: 0;
}
.red-status-text span{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.003em;
    color: #E80054;
    position: relative;
    padding-left: 7px;
}
.red-status-text span:before{
    content: "";
    position: absolute;
    background: #E80054;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    top: 6px;
    left: 0;
}
.transaction-coins{
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 700;
}
.transaction-coins img{
    width: 12px;
    height: 12px;
}
.game-provider{
    font-weight: 700;
}
