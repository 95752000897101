.redeemed-game .admin-search-wrapper {
    padding-top: 0;
}

.redeemed-game-content .wallet-user-recent-game-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.redeemed-game-content button.red-btn {
    width: 82px;
}

.redeem-popup-content {
    max-width: 598px;

    width: 100%;
    margin: 0px auto;
}

.redeem-popup-content h5 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #0E0D27;
    margin: 0;
    padding-bottom: 40px;
}

.redeem-popup-content .total-balance {
    justify-content: center;
    padding-bottom: 0;
}

.redeem-popup-content .total-balance h6 {
    width: 100%;
    text-align: center;
    letter-spacing: 0.003em;
    text-transform: uppercase;
    color: rgb(14 13 39 / 30%);
}

.redeem-popup-content .total-balance p {
    font-size: 32px;
    line-height: 50px;
    letter-spacing: unset;
}

.redeem-popup-content .total-balance p img {
    width: 30px;
    height: 30px;
}

.redeem-success-content h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #0E0D27;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 10px;
}

.redeem-success-content h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: rgb(14 13 39 / 60%);
    margin: 0;
    padding-bottom: 30px;
}

.redeem-success-content .game-recharge-info {
    max-width: 420px;
}

.redeem-success-content .game-recharge-left img {
    max-width: 130px;
    height: 130px;
}

.redeem-success-content .game-recharge-right h6 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    row-gap: 6px;
}

.redeem-success-content .game-recharge-right h6 span {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: rgb(14 13 39 / 40%);
}

.redeem-success-content .game-recharge-right p {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    align-items: center;
}

.redeem-success-content .game-recharge-right p img {
    width: 30px;
    height: 30px;
}

.redeem-success-content .popup-red {
    max-width: 420px;
    line-height: 30px;
    letter-spacing: 0.003em;
    margin-top: 40px;
}