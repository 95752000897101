.page-header{
    background: #21244E;
    padding: 30px 0px 40px 0px ;
}
.page-header-title h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 20px;
}
.page-header-title p{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgb(255 255 255 / 50%);
    margin: 0;
    padding-bottom: 20px;
}
.wallet-info {
    display: flex;
   justify-content: space-between;
    align-items: center;
}
.wallet-info button{
    background: #E80054;
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    height: 60px;
    gap: 10px;
    max-width: 262px;
    width: 100%;
    border: none;
}
.wallet-info  button:hover{
    background: #E80054;
    border: none;
}
.wallet-info  button:focus, .wallet-info   .btn:active:focus, .wallet-info   .btn:active{
    background: #E80054;
    border: none;
    box-shadow: none;
}
.total-coins p{
    font-weight: 700;
    font-size: 28px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    gap: 10px;
    align-items: center;
}
.total-coins p img{
    width: 30px;
    height: 30px;
}
.coins-value-box p{
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}
.coins-value-box p span{
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: right;
    color: rgb(255 255 255 / 70%);
}