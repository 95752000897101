.my-game-page .wallet-user-recent-game-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.waiting-game-content .recent-game-detail-bottom {
  align-items: flex-end;
}
.recent-game-detail-right p {
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  text-align: right;
  color: rgb(255 255 255 / 60%);
  margin: 0;
  padding-bottom: 4px;
}

.game-info-popup .modal-content {
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 590px;
  margin: 0 auto;
  border: none;
}
.game-info-popup .modal-header {
  border: none;
  padding: 0px;
}
.game-info-popup .btn-close {
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 40px;
  top: 40px;
}
.game-info-popup .modal-body {
  padding: 40px 60px 60px;
}
.game-info-banner {
  position: relative;
  width: 100%;
}
.game-info-banner img {
  height: 130px;
  width: 100%;
}
.game-info-name {
  position: absolute;
  top: 0;
  background: rgb(14 13 39 / 70%);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 100%;
  padding: 40px 40px 40px 60px;
}
.game-info-name h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
}
.game-info-wrapper h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
}
.game-info-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px dashed rgb(14 13 39 / 10%);
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.game-info-left p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #0e0d27;
  margin: 0;
}
.game-info-right p {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: #2e8be6;
  text-align: right;
  margin: 0;
}
.account-info-content {
  margin-top: 25px;
}

.popup-footer-btn {
  display: flex;
  padding-top: 45px;
  justify-content: space-between;
}
.popup-black {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  max-width: 220px;
  width: 100%;
  padding: 16.5px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #0e0d27;
}
.popup-black:hover {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  color: #0e0d27;
  box-shadow: none;
}
.popup-black:focus,
.popup-black.btn:active:focus {
  box-shadow: none;
  color: #0e0d27;
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
}

.popup-red {
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  max-width: 220px;
  width: 100%;
  border: none;
  padding: 16.5px 0;
}
.popup-red:hover {
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.5);
}
.popup-red:focus,
.popup-red.btn:active:focus {
  box-shadow: none;
}
.game-request-popup .modal-fullscreen {
  margin: 40px auto;
  width: 66vw;
}

.game-request-popup .modal-content {
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 1260px;
  margin: 0 auto;
  border: none;
}
.game-request-popup .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}
.game-request-popup .btn-close {
  background: url("../../../assets/images/offer/close-icon.svg") center;
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
}
.game-request-popup .modal-body {
  padding: 0px;
  color: #0e0d27;
}
.game-request-wrapper {
  padding: 0px 40px 60px 40px;
}
.game-request-heading h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 5px;
}
.game-request-heading p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgb(14 13 39 / 60%);
  margin: 0;
}
.game-request-search .admin-search-wrapper {
  padding-top: 20px;
}
.game-request-search .search-box input,
.game-request-search .search-box input:focus {
  background: rgb(14 13 39 / 10%);
  border-radius: 8px;
  color: rgb(14 13 39 / 40%);
}
.game-request-search .search-box input::placeholder {
  color: rgb(14 13 39 / 40%);
}
.game-request-list .wallet-user-recent-game-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding-right: 20px;
}
.game-request-list {
  overflow-y: auto;
  height: calc(100vh - 415px);
}
.game-request-list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.game-request-list::-webkit-scrollbar-track {
  background: rgb(170 170 170 / 20%);
}

.game-request-list::-webkit-scrollbar-thumb {
  background-color: #e80054;
}

.game-request-list .recent-game_box img {
  max-width: 262px;
  box-shadow: unset;
}
.select-game-request {
  position: absolute;
  right: 20px;
  top: 10px;
}

.select-game-request .form-check-input {
  background: rgb(255 255 255 / 50%);
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin: 0;
}

.select-game-request .form-check-input:checked[type="checkbox"] {
  background: url("../../../assets/images/game/red-tick.svg") no-repeat;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: none;
  background-position: center;
}
.select-game-request .form-check-input:focus {
  box-shadow: none;
}

.process-popup.modal-content {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 850px;
  margin: 0 auto;
}
.process-popup .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}
.process-popup .btn-close {
  background: url("../../../assets//images/offer/close-icon.svg") center;
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
}
.process-popup .modal-body {
  padding: 0px 40px 60px 40px;
  text-align: center;
}

.game-request-popup .popup-footer-btn {
  background: #f3f3f4;
  padding: 30px 0px;
  justify-content: center;
  gap: 30px;
}
.game-request-popup .popup-black,
.game-request-popup .popup-red {
  max-width: 270px;
}

.new-game-wrapper form {
  padding: 0px 40px 60px 40px;
}

.new-game-wrapper .form-group {
  margin-bottom: 20px;
}
.new-game-wrapper label,
.select-banner-area p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #0e0d27;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.new-game-wrapper .form-control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
  padding: 15px 20px;
  height: 44px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
}
.new-game-wrapper .form-control:focus {
  box-shadow: none;
}
.new-game-wrapper textarea.form-control {
  font-weight: 400 !important;
  height: 150px;
}
.new-game-wrapper .form-control::placeholder {
  color: #0e0d27;
}

.brand-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.brand-input .form-group {
  max-width: 48%;
  width: 100%;
}

.upload-game-images .form-control {
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

.upload-logo-thumnail {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.upload-logo-thumnail .no-image-area {
  overflow: unset;
  height: 250px;
  padding: 0px 30px;
}

.upload-game-thumnail label {
  margin: 0;
  background: #f3f3f4;
  border: 1px dashed #0e0d27;
  border-radius: 10px;
  cursor: pointer;
  height: 250px;
  max-width: 250px;
  width: 100%;
  text-align: center;
}
.upload-game-thumnail label img {
  height: 250px;
  max-width: 250px;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.no-image-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 245px;
  padding: 8px 30px;
  position: relative;
  gap: 8px;
  overflow: auto;
}
.no-image-area label {
  margin: 0;
}

.no-image-area::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.no-image-area::-webkit-scrollbar-track {
  background: rgb(170 170 170 / 20%);
}

.no-image-area::-webkit-scrollbar-thumb {
  background-color: #e80054;
}
.no-image-area .preview-banner {
  position: relative;
  max-width: 150px;
  width: 100%;
  height: 110px;
}
.no-image-area .crossImg {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.8;
  height: auto;
}
.no-image-area .crossImg:hover {
  opacity: 1;
}
.no-image-area .previewImg {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
}
.no-image-area h6 {
  font-size: 14px;
  margin: 0;
  line-height: 20px;
}
.no-image-area h6 span {
  text-decoration: underline;
}

.no-image-area .crossImg {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.8;
  height: auto;
}
.no-image-area .crossImg:hover {
  opacity: 1;
}
