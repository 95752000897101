.page-not-found .brand-logo img{
    width: 80px;
    height: 80px;
}

.stars .custom-navbar{
    padding: 15px 0;
}

.stars .btn-go-home{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    text-decoration: none;
    max-width: 245px;
    width: 100%;
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #FFFFFF;
}

.stars .btn-go-home:hover{
    color: #fff;
    transform: scale(1.02);
    box-shadow: 0px 20px 20px rgb(0 0 0 / 10%);
}

.stars .central-body{
    text-align: center;
    padding: 20px 0; 
    padding-top: 10%;
}
.central-body h6{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: 30px auto 15px;
}
.central-body p{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 0 auto 30px;
    max-width: 271px;
    text-align: center;
    width: 100%;
}

.stars .objects img{
    z-index: 90;
    pointer-events: none;
}

.stars .image-404{
    position: relative;
    z-index: 100;
    pointer-events: none;
}

.page-not-found{
    background: url("../../assets/images/home/bg.png") #0E0D27;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
}


@media only screen and (max-width: 767px){
    .stars .navbar-links{
        display: none;
    }

    .stars .image-404 {
        width: 200px;
        position: relative;
        z-index: 100;
        pointer-events: none;
    }
    .page-not-found .brand-logo img{
        width: 50px;
        height: 50px;
    }
    
}