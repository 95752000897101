.footer {
  padding-top: 60px;
  padding-bottom: 80px;
  position: relative;
  background: #0e0d27;
}

.top-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 44px;
}
.top-footer img {
  height: 32px;
}
.main-footer {
  position: relative;
}

.main-footer .logo {
  margin-top: 62px;
  margin-bottom: 30px;
}

.main-footer .logo img {
  max-width: 80px;
  width: 100%;
  height: 80px;
}

.main-footer-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-info {
  max-width: 350px;
  width: 100%;
}

.footer-info h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #aca2b4;
  margin: 0;
}

.copyright-text p {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.copyright-text p span {
  color: #aca2b4;
  font-weight: 400;
}

.mail-box {
  max-width: 290px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.mail-box input,
.mail-box input:focus {
  background: #ffffff;
  box-shadow: 0px 14px 40px -15px rgba(145, 145, 162, 0.2);
  border-radius: 8px;
  border: none;
  padding: 10px 15px;
  padding-right: 80px;
  height: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
}

.mail-box button,
.mail-box button:hover,
.mail-box button:focus {
  border-color: transparent;
  background: #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 6px;
  position: absolute;
  right: 2px;
  top: 2px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  width: 80px;
  height: 36px;
}
.mail-box button:hover {
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 50%);
}

.mail-box input::placeholder {
  color: #666666;
}
.footer-links h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  margin: 0;
  padding-bottom: 15px;
}

.footer-links ul {
  list-style-type: disc;
  padding: 0;
  margin: 0;
  padding-left: 20px;
}
.footer-links ul li {
  position: relative;
}
.footer-links ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #aca2b4;
  position: relative;
  text-decoration: none;
}
.footer-links ul li::marker {
  color: #2e8be6;
  border-radius: 5px;
  width: 5px;
  height: 5px;
}
.socail-links h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  margin: 0;
  padding-bottom: 20px;
}
.contact-us h6 {
  padding-top: 25px;
}
.contact-us a,
.contact-us a:hover {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #aca2b4;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.socail-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 25px;
}

/* ******** Contact Us CSS ************ */
.contact-page {
  background: url("../../assets/images/footer/contact-bg.png");
  width: 100%;
  background-size: 100% 100%;
  height: 100%;
  padding-top: 78px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position: center;
}
.contact-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-left {
  max-width: calc(100% - 680px);
  width: 100%;
}
.get-in-touch {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.get-in-touch h1 {
  font-weight: 700;
  font-size: 62px;
  line-height: 80px;
  color: #ffffff;
  margin: 0;
}
.get-in-touch p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #aca2b4;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 428px;
}
.get-in-touch a,
.get-in-touch a:hover {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  gap: 15px;
}
.get-in-touch img {
  width: 20px;
  height: 20px;
}
.follow-us h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  margin: 0;
  padding-bottom: 20px;
}
.follow-us ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 40px;
}
.follow-us a,
.follow-us a:hover {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #2e8be6;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.contact-right {
  max-width: 557px;
  width: 100%;
}
.contact-box {
  background: rgba(66, 85, 183, 0.1);
  border: 1px solid rgba(74, 38, 171, 0.5);
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 20px;
  padding: 40px;
}
.contact-box .form-group {
  margin-bottom: 20px;
}
.contact-box .form-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 6px;
}
.contact-box .form-control,
.contact-box .form-control:focus {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #e80054;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  border: none;
  padding: 10px 15px;
  box-shadow: none;
}
.contact-box textarea {
  height: 180px;
}
.max-limit {
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: right;
  color: rgb(255 255 255 / 40%);
  padding-top: 5px;
}
.contact-box .s-btn.btn.btn-primary {
  max-width: 174px;
  margin-top: 0px;
  padding: 15px 40px;
}
