.coin-popup .modal-content {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 850px;
  margin: 0 auto;
}

.coin-popup .modal-dialog {
  max-width: 850px;
  width: 100%;
}
.coin-popup .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}
.coin-popup .modal-body {
  padding: 0;
}
.coin-popup .btn-close {
  background: url("../../../assets/images/wallet/popupcross.svg") center;
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
  filter: opacity(0.2);
}

.coin-popup-content {
  text-align: center;
  padding: 15px 0 80px;
}

.coin-popup-content h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #0e0d27;
  padding-bottom: 5px;
  margin: 0;
}

.coin-popup-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #0e0d27;
  opacity: 0.6;
  margin: 0 auto;
  max-width: 485px;
}

.game-box {
  background: #f3f3f4;
  border-radius: 10px;
  max-width: 412px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 15px;
  margin-top: 30px;
}

.coin-popup-content .game-box-right p {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #0e0d27;
}
.coin-game-box-img {
  text-align: start;
}

.coin-game-box-img span {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #2e8be6;
  padding-left: 10px;
}

.game-box-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 40px;
}
.game-box-btn .transparent-btn button,
.game-box-btn .transparent-btn button:hover,
.game-box-btn .transparent-btn button:focus {
  width: 190px;
  height: 60px;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #0e0d27;
  background: transparent;
}
.game-box-btn .transparent-btn button:hover {
  box-shadow: none;
}
.game-box-btn .red-btn button,
.game-box-btn .red-btn button:hover,
.game-box-btn .red-btn button:focus {
  width: 190px;
  height: 60px;
  background: #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}
.game-box-btn .red-btn button:hover {
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.50);
}
