.client-profile-page .container{
  padding-right: 0;
}
.wallet-page-content {
  display: flex;
  justify-content: space-between;
}

.wallet-page {
  max-width: calc(100% - 315px);
  width: 100%;
  padding: 30px 0px;
}
.wallet-page h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
}
.wallet-user-content {
  background: url(../../../assets/images/wallet/bluebg.png) no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 12px;
}
.wallet-user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 30px;
  margin: 30px 0;
}

.wallet-user-text {
  padding-left: 30px;
}

.wallet-user-left {
  display: flex;
  align-items: center;
}
.wallet-userimg img{
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.wallet-user-text h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 5px;
}

.wallet-user-text a {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
}
.wallet-user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 30px;
  margin: 30px 0;
  width: 100%;
}

.wallet-user-text {
  padding-left: 30px;
}


.wallet-user-text h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 5px;
}

.wallet-user-text a {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
}

.wallet-user-level {
  display: flex;
  width: 100%;
  background: rgba(66, 85, 183, 0.1);
  border: 1px solid rgba(66, 85, 183, 0.5);
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin-bottom: 20px;
}

.wallet-user-level-left {
  max-width: 284px;
  width: 100%;
  background: rgba(46, 139, 230, 0.1);
  border-radius: 9px 0px 0px 9px;
  text-align: center;
  padding: 19px 30px 34px;
}
.wallet-user-level-left .progress {
  width: 100%;
  height: 100%;
}
.CircularProgressbar-path {
  stroke: #E80054;
  stroke-width: 5px;
}
.CircularProgressbar-trail {
  stroke: rgb(127 171 213);
  stroke-width: 5px;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}
.current-level-pro {
  position: relative;
  max-width: 140px;
  height: auto;
  margin: 0px auto;
  padding-bottom: 15px;
}
.current-level-img {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
}
.current-level-img img {
  max-width: 123px;
  width: 100%;
  height: auto;
}

.wallet-user-level-left p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding-bottom: 21px;
}

.wallet-user-level-left h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #e80054;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 10px;
}

.wallet-user-level-left span {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
}
.wallet-user-level-right {
  width: 100%;
  padding: 20px 30px 30px;
}

.wallet-user-progress h3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 10px;
}

.wallet-user-newplayer {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 4px;
}
.user-newplayer-img img {
  width: 50px;
  height: 58px;
}
.user-newplayer {
  display: flex;
  align-items: center;
}

.user-newplayer-img {
  padding: 0 15px 0 20px;
}

.user-newplayer-text h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: #2e8be6;
  margin: 0;
}

.user-newplayer-text p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: #ffffff;
  opacity: 0.8;
  margin: 0;
}

.user-newplayer-prize span {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}

.wallet-loyal-newplayer .wallet-user-newplayer {
  background: transparent;
  padding: 0;
}

.wallet-loyal-newplayer .user-newplayer {
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  width: 75%;
  background: #2e8be626;
}
.wallet-loyal-newplayer .user-newplayer-prize {
  background: #ffffff1a;
  border-radius: 0 10px 10px 0;
  padding: 24px;
  width: 25%;
  max-width: 100%;
  text-align: end;
}
.recent-game_box img {
  max-width: 255px;
  width: 100%;
  height: 260px;
  box-shadow: 0px 14px 50px -6px rgba(18, 15, 39, 0.4);
  border-radius: 10px;
}

/************  wallet-recent-game *********************/

.wallet-user-recent-game {
  padding-top: 20px;
}

.wallet-user-recent-game h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}
.wallet-user-recent-game {
  padding-top: 20px;
}

.wallet-user-recent-game h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.recent-game-detail {
  position: absolute;
  bottom: 0;
  background: #161130e6;
  border-radius: 10px;
  max-width: 235px;
  width: 100%;
  margin: 10px;
}
.wallet-user-recent-game-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.recent-game_box {
  position: relative;
}

.recent-game-detail-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.recent-game-detail-left p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.recent-game-detail-img img {
  width: 12px;
  height: 9px;
}

.recent-game-detail-img span {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #2e8be6;
  padding-left: 5px;
}
.recent-game-detail-right button.red-btn {
  width: 70px;
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 8px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
}
.recent-game-detail-right button.red-btn:hover {
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.50);
}
.recent-game-detail-right button.btn:active:focus {
  box-shadow: none;
}

.wallet-user-progress .progress-bar {
  position: relative;
  background: #2e8be6;
  /* opacity: 0.15; */
  height: 70px;
}

.wallet-user-progress .progress {
  /* position: relative; */
  height: 70px !important;
  background: #ffffff;
  opacity: 0.1;
}

.wallet-user-progress {
  position: relative;
  margin-top: 10px;
}

.wallet-user-progress-item {
  position: absolute;
  top: 0;
  width: 100%;
}
