.select-banner-area {
  width: 48%;
}

.select-banner-option {
  display: flex;
  align-items: center;
  gap: 20px;
}

.select-banner-option .react-date-picker__wrapper {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
  height: 44px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.select-banner-option .react-date-picker {
  width: 100%;
}

.select-banner-option .react-calendar {
  border: 1px solid rgb(124 116 142 / 20%);
}

.faq-footer {
  background: transparent !important;
}

.select-banner-option .deposit-cash-app [type="radio"]:checked + label:before,
.select-banner-option
  .deposit-cash-app
  [type="radio"]:not(:checked)
  + label:before {
  border: 1px solid rgb(124 116 142 / 20%);
}

.select-banner-option .deposit-cash-app [type="radio"]:checked + label,
.select-banner-option .deposit-cash-app [type="radio"]:not(:checked) + label {
  padding-left: 32px;
  padding-top: 2px;
}

@media screen and (max-width: 767px) {
  .select-banner-area {
    width: 100%;
  }

  .select-banner-area:last-child {
    margin-bottom: 10px;
  }

  .select-banner-option .form-check {
    padding: 0;
  }
}
