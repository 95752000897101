.header-wrapper{
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}
.header{
  padding: 10px 0px 10px;
  background: rgb(18 19 56 / 97%);
  box-shadow: 0px 34px 80px rgba(25, 11, 39, 0.35);
  position: relative;
}

.header-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo h2 {
    margin: 0;
}


.header .logo img {
    max-width: 50px;
    width: 100%;
    height: 50px;
}

.animated-logo {
    position: relative;
}
.animated-logo img{
  max-width: 80px;
  height: 80px;
}

.animated-logo .animate-icon {
    max-width: 100px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}



@-webkit-keyframes mover {
    0% {
      transform: translateX(-50%) translateY(0);
    }
    100% {
      transform: translateX(-50%) translateY(-10px);
    }
  }
  @keyframes mover {
    0% {
      transform: translateX(-50%) translateY(0);
    }
    100% {
      transform: translateX(-50%) translateY(-10px);
    }
  }

  

.auth-box a{
  background: #E80054;
  border: 2px solid #E80054;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  transition: all 0.5s;
  max-width: 120px;
  width: 100%;
  height: 50px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-box a:hover {
  background: #E80054;
  border-color: #E80054;
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 50%);
  color: #FFFFFF;
}

.auth-box {
    display: flex;
    flex-wrap: wrap;
    max-width: 240px;
    width: 100%;
}

.auth-box a:first-child, .auth-box a:first-child:hover{
    background: transparent;
    border: none;
    box-shadow: none;
}

.user-loggedin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 240px;
  width: 100%;
  position: relative;
  padding-left: 40px;
}
.user-loggedin:before{
  content: "";
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 54.06%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  width: 1px;
  height: 40px;
  position: absolute;
  left: 0;
  opacity: 0.3;
  transform: translate(0px, 6px);
}
.notification-btn {
  padding-left: 38px;
  cursor: pointer;
  position: relative;
}
.red-icon{
  background: #E80054;
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 100%;
  top: 4px;
}
span.wallet-section{
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: #FFFFFF;
}
span.wallet-section img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}


.user-pic .dropdown.show:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 16px;
  background: url("../../assets/images/header/arrow.svg") no-repeat;
  bottom: -25px;
  left: 50%;
  transform: translate(-50%);
}

.user-pic .btn-primary {
  background: transparent;
  border: none;
  gap: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0;
}

.user-pic .btn-check:active+.btn:focus,
.user-pic .btn-check:checked+.btn:focus,
.user-pic .btn.active:focus,
.user-pic .btn.show:focus,
.user-pic .btn:active:focus, 
.user-pic .btn:hover,
.user-pic .btn:active,
.user-pic .btn:focus {
  box-shadow: none;
  background: transparent;
}

.user-pic .btn-primary img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
}
.user-pic .dropdown-toggle::after {
  background: url("../../assets/images/header/profile-arrow.svg");
  position: absolute;
  width: 8px;
  height: 8px;
  right: -15px;
  border: 0;
  margin: 0;
}

.user-pic .dropdown-menu {
  padding: 10px 0px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #E70054;
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin-top: 20px;
  overflow-y: auto;
}

.user-pic .dropdown-item {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  letter-spacing: 0.003em;
  padding: 5px 15px;
}

.user-pic .dropdown-item:focus,
.user-pic .dropdown-item:hover {
  background: rgb(206 2 80 / 30%);
  color: #FFFFFF;
}

.logout-btn {
  margin-left: 20px;
  cursor: pointer;
}

.top-header{
  background: #E80054;
  text-align: center;
  height: 25px;
}
.top-header a, .top-header a:hover{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #FFFFFF;
  text-decoration: none;
}
.top-header span{
  font-weight: 700;
}

.header-menu-section{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-width: calc(100% - 402px);
  width: 100%;
}
.header-menu{
  max-width: calc(100% - 396px);
  width: 100%;
}
.navbar-expand-lg .navbar-nav {
  justify-content: space-between;
  width: 100%;
}
.header-menu .navbar-expand-lg .navbar-nav .nav-link{
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #FFFFFF;
  text-decoration: none;
  padding: 2px 10px;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all 0.5s;
}
.header-menu .navbar-expand-lg .navbar-nav .active{
  border: 1px solid #E80054;
  border-radius: 10px;
  transition: all 0.5s;
}
.header-menu .navbar-expand-lg .navbar-nav .nav-link:hover{
  border-color:  #E80054;
  border-radius: 10px;
  color: #FFFFFF;
}
.header-menu .navbar-toggler, .header-menu .navbar-toggler:focus{
  background: transparent;
  box-shadow: none;
}
.header-menu .navbar-toggler.collapsed .navbar-toggler-icon{
  background-image: url("../../assets/images/header/menu.svg");
  width: 40px;
  transition: all 0.5s; 
}
.header-menu .navbar-toggler .navbar-toggler-icon{
  background-image: url("../../assets/images/header/cross-menu.svg");
  width: 25px;
}
