.game-page {
    background: #0E0D27;
}

.game-banner {
    background: url("../../assets/images/game/game-banner.png");
    width: 100%;
    background-size: 100% 100% !important;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 200px;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.game-left {
    max-width: 540px;
    width: 100%;
}

.game-left h1 {
    font-weight: 700;
    font-size: 62px;
    line-height: 80px;
    color: #FFFFFF;
    margin: 0;
}

.games-tab-section {
    background: url("../../assets/images/game/game-bg.png");
    width: 100%;
    background-size: cover;
    height: 100%;
    padding: 120px 0px;
    background-repeat: no-repeat;
    background-position: center;
}

.all-games-content .popular-tab-content {
    padding-top: 60px;
}

.games-tab-grid ul.nav.nav-tabs {
    border-bottom: none;
    gap: 26px;
    justify-content: space-between;
}

.games-tab-grid li.nav-item {
    max-width: 120px;
    width: 100%;
}

.games-tab-grid .nav-tabs .nav-link {
    border: none;
    margin: 0;
    background: rgba(46, 139, 230, 0.1);
    border-radius: 10px;
    padding: 20px 0px;
    width: 100%;
    transition: all 0.5s;
}

.games-tab-grid .nav-tabs .nav-link h6 {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
}

.games-tab-grid .nav-tabs .nav-link img {
    max-width: 40px;
    width: 100%;
    margin: 0px auto;
}

.games-tab-grid .nav-tabs .nav-item.show .nav-link,
.games-tab-grid .nav-tabs .nav-link.active, .games-tab-grid .nav-tabs .nav-link:hover {
    color: #FFFFFF;
    background: rgb(232 0 84 / 80%);
    box-shadow: 0px 14px 50px -6px rgba(232, 0, 84, 0.7);
    border-radius: 10px;
}

.show-more {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.show-more-link,
.show-more-link:hover,
.show-more-link:focus,
.show-more-link:active {
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.003em;
    text-decoration-line: underline;
    color: #E80054;
    background: transparent;
    border: none;
    padding: 0;
    outline: 0;
    box-shadow: none;
}

.brand-game-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-top: 60px;
}


/* ************ Brand Game CSS *************** */
.brand-detail-page {
    background: url("../../assets/images/game/brand-bg.png") #0C0A40;
    width: 100%;
    background-size: cover;
    height: 100%;
    padding-top: 15px;
    background-repeat: no-repeat;
    background-position: center;
}

.brand-breadcrumb ul {
    align-items: baseline;
    display: flex;
    gap: 15px;
    padding: 0;
    list-style: none;
}

.brand-breadcrumb li a,
.brand-breadcrumb li,
.brand-breadcrumb li a:hover {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ACA2B4;
    text-decoration: none;
}

.brand-breadcrumb li {
    position: relative;
}

.brand-breadcrumb li:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 10px;
    background: #ACA2B4;
    padding: 0;
    top: 5px;
    right: -7px;
}

.brand-breadcrumb li:last-child:after {
    display: none;
}

.brand-banner-image {
    position: relative;
    padding-top: 20px;
}

.brand-banner-image img {
    width: 100%;
    height: 450px;
    border-radius: 10px;
    filter: drop-shadow(0px 20px 60px rgba(14, 13, 39, 0.5));
    object-fit: cover;
}

.brand-info-section {
    position: absolute;
    width: 100%;
    bottom: 40px;
    padding: 0px 40px;
}

.brand-info-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.brand-info-left {
    display: flex;
    align-items: center;
    gap: 36px;
}

.brand-logo img {
    width: 100px;
    height: 100px;
    min-height: unset;
}

.brand-info-name h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 5px;
}

.brand-info-name p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #2E8BE6;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.brand-info-name img {
    width: 18px !important;
    height: 14px !important;
}

.brand-info-right {
    max-width: 250px;
    width: 100%;
}

.dropdown-area .btn-primary {
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 30px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 5px 22px 5px 5px;
    gap: 7px;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.dropdown-area .btn-check:active+.btn:focus,
.dropdown-area .btn-check:checked+.btn:focus,
.dropdown-area .btn.active:focus,
.dropdown-area .btn.show:focus,
.dropdown-area .btn:active:focus {
    box-shadow: none;
}

.dropdown-area .btn-primary img {
    width: 40px;
    height: 40px;
    min-height: unset;
}

.dropdown-area .dropdown-menu {
    padding: 20px 0px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #E70054;
    box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    margin-top: 10px;
    padding-right: 10px;
}

.dropdown-area .dropdown-item {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 5px 20px;
}

.dropdown-area .dropdown-item:focus,
.dropdown-area .dropdown-item:hover {
    background: rgb(231 0 84 / 30%);
}

.dropdown-area .dropdown-item img {
    width: 30px;
    height: 30px;
    min-height: unset;
}
.brand-info-container{
    max-height: 230px;
    overflow-y: auto;
}

.brand-info-container::-webkit-scrollbar {
    width: 2px;
    height: 3px;
}

.brand-info-container::-webkit-scrollbar-track {
    border-radius: 0px;
    padding-right: 10px;
}

.brand-info-container::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #2E8BE6;
    padding-right: 10px;
}

.dropdown-area .dropdown-toggle::after {
    background: url("../../assets//images//game/down-arrow.svg");
    position: absolute;
    width: 11px;
    height: 6px;
    right: 20px;
    border: 0;
}

.brand-detail-section {
    padding-top: 80px;
    padding-bottom: 120px;
}

.brand-detail-section h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0;
}

.brand-detail-section h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    margin: 0;
}

.brand-detail-section p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ACA2B4;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 60px;
}

.brand-detail-section button {
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    border: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    max-width: 245px;
    width: 100%;
    padding: 20px 0;
}

.brand-detail-tabs {
    padding: 120px 0;
    background: #0C0A40;
}

.brand-detail-tabs .game-info {
    display: none;
}

.brand-detail-tabs .popular-badge {
    display: none;
}

.brand-detail-tabs .games-tab-grid ul.nav.nav-tabs {
    gap: 50px;
}

/* ************* Game Download CSS ****************** */

.game-download-page {
    background: url("../../assets/images/game/brand-bg.png") #0C0A40;
    width: 100%;
    background-size: cover;
    height: 100%;
    padding-top: 15px;
    background-repeat: no-repeat;
    background-position: center;
}

.relevent-game-content {
    padding-top: 50px;
    padding-bottom: 120px;
}
.download-games-slider{
    position: relative;
}

.download-games-slider h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 30px;
}

.download-game-tiles {
    padding: 0px 10px;
}

.download-game-tiles img {
    width: 100%;
    filter: drop-shadow(0px 20px 60px rgba(14, 13, 39, 0.5));
    border-radius: 10px;
    height: 640px;
    object-fit: cover;
}

.download-games-slider .slick-dots {
    bottom: -110px;
}

.download-games-slider .slick-dots li {
    max-width: 165px;
    width: 100%;
    height: 90px;
    margin: 0 10px;
}

.download-games-slider .slick-dots li img {
    max-width: 165px;
    width: 100%;
    height: 90px;
    border-radius: 10px;
}
.download-games-slider .slick-dots .slick-active img {
    border: 1px solid #E80054;
}

.game-download-page .brand-detail-section {
    padding-top: 186px;
}

.download-games-slider .slick-prev {
    width: 30px;
    height: 30px;
    background: rgb(232 0 84 / 20%);
    z-index: 8;
    border-radius: 100px;
    color: #E80054;
    left: -50px;
}

.download-games-slider .slick-next {
    width: 30px;
    height: 30px;
    background: rgb(232 0 84 / 20%);
    z-index: 8;
    border-radius: 100px;
    color: #E80054;
    right: -50px;
}

.download-games-slider .slick-prev::before {
    content: "\f30a";
    font-family: "Line Awesome Free";
    font-weight: 900;
}

.download-games-slider .slick-next::before {
    content: "\f30b";
    font-family: "Line Awesome Free";
    font-weight: 900;
}
.relevent-game-content .popular-small-box img{
    width: 100%;
    height: 220px;
    object-fit: cover;
    box-shadow: 0px 14px 50px -6px rgba(18, 15, 39, 0.4);
    border-radius: 10px;
}
span.pre-btn, span.next-arrow{
    width: 30px;
    height: 30px;
    background: rgb(232 0 84 / 20%);
    border-radius: 100px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    line-height: unset;
    opacity: .75;
    color: white;
    position: absolute;
    top: 45px;
}
.custom-arrow {
    position: relative;
}
span.pre-btn{
    left: -50px;
}
span.next-arrow{
    right: -50px;
}