.admin-wrapper {
    overflow-x: hidden;
    display: flex;
}

.admin-view .main_content {
    padding: 0;
}

.admin-container {
    max-width: calc(100% - 270px);
    width: 100%;
    background: #1F1842;
    margin-left: 270px;
    min-height: calc(100vh - 70px);
    height: 100%;
}
