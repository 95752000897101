.auth-page{
  background: url("../../assets/images/home/bg.png") #0E0D27;
  width: 100%;
  background-size: cover;
  height: 100%;
  padding-top: 75px;
  padding-bottom: 128px;
  background-repeat: no-repeat;
  background-position: center;
}
.register-form {
  margin: 0px auto;
  padding: 0px 0px;
  position: relative;
}

.register-form img {
  max-width: 250px;
  margin: 0px auto;
  display: block;
}

.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid #ff5917;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.auth-page h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  margin: 0;
  padding-bottom: 10px;
}

.auth-page p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: rgb(172 162 180 / 80%);
  margin: 0;
  padding-bottom: 25px;
  position: relative;
}
.auth-para, .reset-password-section h1{
  position: relative;
}
.auth-para:after, .reset-password-section h1:after{
  content: "";
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 51.42%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  max-width: 594px;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 50%;
  opacity: 0.3;
  transform: translate( -50%, 0);
}

.register-form .rigister-tab {
  max-width: 420px;
  margin: 0px auto;
  padding-top: 40px;
  margin-bottom: 0px;
}

.terms-link {
  color: #99a4b0cc;
  text-decoration: underline;
}

.terms-link:hover {
  color: #E80054;
}

.dis-show .form-check {
  display: inline-block;
}

.dis-show p {
  font-size: 13px;
  color: #99a4b0cc;
  margin: 0;
  text-align: left;
}

.dis-show {
  display: flex;
  align-items: flex-start;
}

.s-btn.btn.btn-primary {
  padding: 20px 40px;
  background: #E80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #FFFFFF;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 30px;
}

.s-btn.btn.btn-primary:hover,
.s-btn.btn.btn-primary:focus,
.s-btn.btn.btn-primary:active {
  background: #E80054;
  box-shadow: none;
  border: none;
}

.register-form .form-group {
  margin-bottom: 20px;
  position: relative;
}
.input-icon{
  position: absolute;
  right: 20px;
  top: 50px;
  color: #2E8BE6;
  cursor: pointer;
}
.check-field{
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-top: 10px;
}
.check-field .form-label{
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #ACA2B4 !important;
  margin: 0 !important;
}
.check-field  a, .check-field a:hover{
  color: #E80054;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 0.003em;
}
.check-field .form-check-input[type=checkbox]{
  width: 20px;
  height: 20px;
  border: 1px solid #79748E;
  border-radius: 4px;
  background: transparent;
  margin: 0;
}
.check-field .form-check-input:focus{
  box-shadow: none;
}
.check-field  .form-check-input:checked[type=checkbox]{
  background: url("../../assets/images/header/checkTick.svg");
  width: 20px;
  height: 20px;
  background-position: center;
  border: none;
}

.register-form .form-group .form-control {
  height: auto;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7C748E;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
}
.register-form .form-group .form-control:-webkit-autofill{
  box-shadow: unset;
}

.register-form .form-group .form-control::placeholder {
  color: #FFFFFF;
}

.register-form .form-group .form-control:focus {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.05);
  color: #FFFFFF;
}

.register-form input::-internal-autofill-selected {
  background: transparent !important;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border: 1px solid #31343c66;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #31343c66 inset;
  transition: background-color 5000s ease-in-out 0s;
  outline: none;
}

.signup .form-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 10px;
}
.signup .form-label span{
  font-weight: 400;
  letter-spacing: 0.003em;
}

.already {
  padding-top: 40px;
  padding-bottom: 0 !important;
  font-size: 14px!important;
  font-weight: 400 !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  border: none !important;
}

.signup {
  position: relative;
}

input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

input[type="date"]:before {
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 15px;
  color: #99a4b0cc;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.signup-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.signup-popup .modal-header .close:focus {
  outline: none;
}

.signup-popup .close {
  color: #99a4b0cc;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
  cursor: pointer;
}

.signup-popup .close:hover {
  color: #efebeb;
}

.signup-popup .modal-content {
  position: relative;
  background: #1e2024;
  background-size: cover;
  background-position: center;
}

.signup-popup .modal-body {
  padding-top: 0;
}

.signup-popup .modal-dialog{
  max-width: 475px;
}

.error-text {
  text-align: left!important;
  max-width: 100%!important;
  font-size: 10px!important;
  font-weight: 400!important;
  padding: 5px 0px 0px!important;
  margin: 0!important;
  border: none !important;
  line-height: 10px !important;
  color: #FF6C01 !important;
}

.register-form .form-group.check-field {
  flex-wrap: wrap;
}

.error-field.form-control {
  border-color: #FF6C01!important;
  background: rgba(255, 108, 1, 0.1) !important;
  border-radius: 2px !important;
}

.register-form .form-group.check-field p {
  width: 100%;
}

.success-msg{
  text-align: center;
}

.half-width {
  display: flex;
  justify-content: space-between;
}

.half-width .form-group {
  width: 48%;
}


.register-form .react-tel-input .country-list .country-name {
  color: #fff;
}

.register-form .react-tel-input .country-list .country .dial-code {
  color: #fff;
}

.register-form .react-tel-input .selected-flag:focus:before, .register-form .react-tel-input .selected-flag.open:before{
  display: none;
}

.register-form .react-tel-input .country-list{
  width: 200px;
  background-color: #26282e;
}


.register-form .react-tel-input .country-list::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #1e2024;
}

.register-form .react-tel-input .country-list::-webkit-scrollbar
{
	width: 0px;
	background-color: #1e2024;
}

.register-form .react-tel-input .country-list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #1e2024;
}


.register-form .react-tel-input .country-list .country:hover, .register-form .react-tel-input .country-list .country.highlight {
  background-color: #1e2024;
}

.register-form .react-tel-input .form-control {
  padding-left: 50px!important;
}

.success-msg {
  text-align: center;
  font-size: 18px;
  margin: 0;
  padding-top: 20px;
  color: #56d538;
}