.about-page{
    background: #080422;
}
.about-banner {
    background: url("../../assets/images/about/about-banner.png") #080422;
    width: 100%;
    background-size: 100% 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 200px;
    background-repeat: no-repeat;
    background-position: center;
}

.about-feature-section{
    padding: 120px 0px;
    position: relative;
    background: url("../../assets/images/about/feature-bg.png");
    background-size: cover;
    height: 100%;
    width: 100%;
}
.about-feature-section .our-feature-box p{
    max-width: 350px;
}
.play-game-grid{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
}

.play-game-left{
    max-width: 525px;
    width: 100%;
}
.pg-left-content h2{
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 20px;
    max-width: 400px;
}
.pg-left-content p{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ACA2B4;
    margin: 0;
    padding-bottom: 10px;
}
.pg-tags {
    display: flex;
    gap: 50px;
    padding-top: 20px;
    padding-bottom: 50px;
}
.pg-tags h6{
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
}
.pg-left-content button, .pg-left-content button:hover{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 22px;
    max-width: 245px;
    width: 100%;
    border: none;
}
.pg-left-content button:hover {
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.50);
}
.play-game-right{
    max-width: calc(100% - 653px);
    width: 100%;
    padding-top: 70px;
    padding-right: 30px;
}
.play-game-images {
    position: relative;
}
.play-game-images img{
    box-shadow: 0px 14px 50px -6px rgba(18, 15, 39, 0.4);
    border-radius: 10px;
}
.pg-center-pic{
    text-align: right;
}
.pg-center-pic img{
    position: relative;
    width: 	362px;
    height: 303px;
}
.pg-top-left-pic {
    position: absolute;
    top: -60px;
    left: 50px;
    z-index: 9;
}
.pg-top-left-pic img {
    width : 199px;
    height: 166px
}
.pg-top-right-pic{
    position: absolute;
    top: -25px;
    right: -30px;
    z-index: 9;
}
.pg-top-right-pic img{
    width : 111px;
    height: 92px
}
.pg-bottom-left-pic {
    position: absolute;
    bottom: -60px;
    left: 60px;
}
.pg-bottom-left-pic img{
    width : 131px ;
    height: 109px
}
.pg-bottom-right-pic{
    position: absolute;
    bottom: -40px;
    right: -30px;
}
.pg-bottom-right-pic img{
    width : 193p×;
    height: 160px
}

.distributor-top-section{
    background: #0C0A40;
    padding-top: 120px;
}
.manage-sytem{
    padding-top: 30px;
}
.manage-sytem h2{
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 20px;
}
.manage-sytem p{
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #D2D1D9;
    margin: 0;
}
.key-points-grid ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    padding-top: 50px;
    list-style: none;
    gap: 30px;
}
.key-points-grid li{
    background: rgb(46 139 230 / 10%);
    opacity: 0.8;
    border: 1px solid #2E8BE6;
    border-radius: 10px;
    padding: 30px 40px;
    max-width: 358px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
    height: 150px;
    display: flex;
    align-items: center;
}
.key-points-grid li:hover {
    background: rgb(46 139 230 / 20%);
}
.key-points-grid li:last-child{
    max-width: 750px;
}
.become-distributor-section{
    padding-top: 60px;
}
.already-distributor p{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
}
.already-distributor a{
    color: #E80054;
}
.become-distributor-content {
    padding-top: 120px;
}
.become-distributor-banner{
    background: url("../../assets/images/about/distributor-bg.png");
    width: 100%;
    background-size: 100% 100%;
    height: 100%;
    padding-top: 80px;
    padding-left: 120px;
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 9;
    position: relative;
}
.become-distributor-banner h2{
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #FFFFFF;
    padding-bottom: 20px;
    margin: 0;
    max-width: 395px;
}
.become-distributor-banner p{
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #FFFFFF;
    padding-bottom: 40px;
    margin: 0;
    max-width: 347px;
}
.become-distributor-banner button, .become-distributor-banner button:hover, .become-distributor-banner button:focus{
    background: #E80054;
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    max-width: 245px;
    width: 100%;
    padding: 20px;
    border: none;
}
.become-distributor-banner button:hover {
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.50);
}

.warning-section{
    background: #1f1842;
    padding-top: 290px;
    padding-bottom: 120px;
    margin-top: -200px;
}

.warning-section p{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ACA2B4;
    margin: 0;
}


.distributor-popup .modal-content{
    background: #FFFFFF;
    box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
    border-radius: 10px;
    max-width: 850px;
    margin: 0 auto;
}
.distributor-popup .modal-header{
    border: none;
    padding: 40px 40px 0px;
    justify-content: flex-end;
}
.distributor-popup .btn-close{
    background: url("../../assets//images/offer/close-icon.svg") center;
    width: 20px;
    height: 20px;
    opacity: unset;
    border-radius: unset;
    margin: 0;
    padding: 0;
}
.distributor-popup .modal-body{
    padding: 0px 40px 80px 40px;
}
.distributor-popup-content h6{
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #0E0D27;
    margin: 0;
    padding-bottom: 10px;
}
.distributor-popup-content p{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #6E6E7D;
    margin: 0;
}

.distributor-popup-form {
    max-width: 455px;
    width: 100%;
    margin: 0 auto;
    padding-top: 40px;
}
.distributor-popup-form .form-group{
    margin-bottom: 20px;
}
.distributor-popup-form .form-label{
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #0E0D27;
    margin-bottom: 10px;
}
.distributor-popup-form .form-control {
    background: rgba(255, 255, 255, 0.05);
    opacity: 0.2;
    border: 1px solid #7C748E;
    border-radius: 4px;
    height: 50px;
    color: #0E0D27;
    box-shadow: none;
}
.distributor-popup-form .form-control:focus{
    opacity: unset;
}
.distributor-popup-form textarea.form-control {
    height: 210px;
}

.distributor-popup-form .s-btn.btn.btn-primary{
    padding: 15px 40px;
    margin-top: 10px;
}

