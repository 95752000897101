.search-listing-section {
  padding: 10px;
  position: absolute;
  background: #1d1e25;
  border-radius: 5px;
  z-index: 9;
  width: 100%;
  max-height: 53vh;
  overflow: auto;
}

.search-items {
  display: flex;
  background-color: #1c1e25;
  border: 1.3263888888888888px solid #25262e;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.search-items:hover {
  cursor: pointer;
  background-color: #17181e;
}

.search-items:last-child {
  margin-bottom: 0;
}

.search-items>div {
  padding: 5px;
  width: 100%;
}

.search-image {
  max-width: 70px;
}

.search-image img {
  width: 100%;
}

.search-listing-section p {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #ffffff;
  font-weight: 300;
  margin-bottom: 0;
}

.search-listing-section span {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 12px;
  color: #90929a;
  font-weight: 300;
}

/******** Search Box*********/
.search-wrapper {
  max-width: 300px;
  width: 100%;
  position: relative;
}
.search-box {
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.search-box form {
  position: relative;
}

.search-box input,
.search-box input:focus {
  background: #2A294C;
  border: 1px solid transparent !important;
  box-shadow: none;
  padding: 10px 20px;
  padding-left: 40px;
  height: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: #ACA2B4;
}
.search-box input:hover {
  border: 1px solid rgb(255 255 255 / 20%) !important;
}

.search-box button,
.search-box button:hover,
.search-box button:focus,
.search-box button:active:focus {
  background: transparent;
  border-color: transparent;
  position: absolute;
  left: 0;
  color: #E80054;
  box-shadow: none;
  transform: rotate(-90deg);
  font-size: 18px;
}

.search-box input::placeholder {
  color: #ACA2B4;
}

.search-result-section{
  position: absolute;
  top: 54px;
  left: 0px;
  /* max-width: 943px;
  width: 100%; */
  max-width: 943px;
  width: 943px;
  z-index: 9999;
}
.search-result-box{
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(74, 38, 171, 0.5);
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  padding: 32px 40px 40px 40px;
  position: relative;
}
.search-result-box::before{
  content: "";
  position: absolute;
  
}

.search-result-tilte h6{
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #2D89E2;
  margin: 0;
}
.search-result-grid{
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  padding-top: 30px;
}

.search-result-tiles{
  position: relative;
  overflow: hidden;
  cursor: pointer;
  max-width: 154px;
  width: 100%;
}
.search-result-tiles img{
  max-width: 154px;
  width: 100%;
  border-radius: 10px;
}

.search-result-tiles:hover .search-info {
  opacity: 1;
}

.search-info {
  position: absolute;
  inset: 0px;
  padding: 20px;
  opacity: 0;
  transition: all 0.5s ease 0s;
  background: rgba(4, 5, 9, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.search-result-name{
  display: flex;
  justify-content: space-between;
}

.search-result-name h5 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  margin: 0;
}
.search-result-name span{
  height: 12px;
  display: inline-flex;
}

.search-info p {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #ACA2B4;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 30px;
}

.search-result-btn button,
.search-result-btn button:hover {
  background: #E80054;
  box-shadow: 0px 6px 20px -5px rgba(232, 0, 84, 0.25);
  border-radius: 6px;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  padding: 7.5px 0px;
  max-width: 114px;
  width: 100%;
}