.offer-page{
    background: #080422;
}
.offer-banner {
    background: url("../../assets/images/offer/offer-banner.png") #080422;
    width: 100%;
    background-size: 100% 100% !important;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 200px;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.banner-left {
    max-width: 540px;
    width: 100%;
}

.banner-left h1 {
    font-weight: 700;
    font-size: 62px;
    line-height: 80px;
    color: #FFFFFF;
    margin: 0;
}
.banner-left p{
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    color: rgb(255 255 255 / 60%);
    margin: 0;
    padding-top: 30px;
}

.offers-section {
    background: url("../../assets/images/offer/offer-bg.png");
    width: 100%;
    background-size: cover;
    height: 100%;
    padding: 120px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #0C0A40;
}
.offers-section .section-title{
    padding-bottom: 50px;
}

.normal-offer-tiles, .special-offer-tiles{
    padding: 0px 15px;
}
.normal-offer-box{
    position: relative;
    min-height: 420px;
}
.normal-offer-box img{
    width: 100%;
    cursor: pointer;
    height: 260px;
    border-radius: 10px 10px 0 0;
}
.inner-box{
    padding: 0 30px 30px;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    position: relative;
    z-index: 9;
}
.inner-box h5{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.003em;
    text-transform: uppercase;
    color: #FFFFFF;
    max-width: 202px;
    margin: 0 auto;
    padding-bottom: 5px;
}
.inner-box.orange-box h5 {
    max-width: 165px;
}
.inner-box h6{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.003em;
    text-transform: uppercase;
    color: #FFFFFF;
    max-width: 202px;
    margin: 0 auto;
    padding-bottom: 15px;
}
.inner-box button, .inner-box button:hover, .inner-box button:active, .inner-box button:active:focus, .inner-box button:focus{
    background: rgb(255 255 255 / 10%);
    border: 1px solid rgb(255 255 255 / 50%);
    border-radius: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 9px;
    max-width: 202px;
    width: 100%;
    box-shadow: none;
}
.inner-box button:hover {
    background: rgb(255 255 255 / 20%);
}

.normal-offer-box .bg-color{
    position: absolute;
    content: "";
    width: 100%;
    height: 240px;
    left: 0;
    bottom: 0;
    border-radius: 0px 0px 10px 10px;
}
.normal-offer-box.purpule-box::after{
    background: linear-gradient(0deg, #7749F3 59.38%, rgba(119, 73, 243, 0) 100%);
}
.normal-offer-box.orange-box::after{
    background: linear-gradient(0deg, #FF8517 59.38%, rgba(255, 133, 23, 0) 100%);
}

.normal-offer-box.green-box::after{
    background: linear-gradient(0deg, #45B928 59.38%, rgba(68, 184, 39, 0) 100%);
}
.normal-offer-box.violet-box::after{
    background: linear-gradient(0deg, #7748F3 59.38%, rgba(119, 72, 243, 0) 100%);
}

.offers-section .slick-prev, .offers-section .slick-next {
    width: 30px;
    height: 30px;
    background: rgb(232 0 84 / 20%);
    z-index: 8;
    border-radius: 100px;
    color: #E80054;
}
.offers-section .slick-prev{
    left: -45px;
}

.offers-section .slick-next {
    right: -45px;
}

.offers-section .slick-prev::before {
    content: "\f30a";
    font-family: "Line Awesome Free";
    font-weight: 900;
}

.offers-section .slick-next::before {
    content: "\f30b";
    font-family: "Line Awesome Free";
    font-weight: 900;
}
.without-login, .with-login{
    background: rgba(4, 5, 9, 0.8);
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 30px;
    opacity: 0;
    position: absolute;
    inset: 0px;
    z-index: 99;
    transition: all 0.5s;
}
.with-login{
    z-index: unset;
}
.normal-offer-box:hover .without-login {
    opacity: 1;
}

.without-login h5{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.003em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 5px;
}
.without-login h6{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.003em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}
.without-login p{
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #ACA2B4;
    margin: 0;
    padding-top: 20px;
}
.without-login-btn{
   padding-top: 30px;
   display: flex;
   flex-wrap: wrap;
   gap: 15px;
}
.without-login-btn a, .without-login-btn a:hover{
    background: #E80054;
    box-shadow: 0px 6px 20px -5px rgba(232, 0, 84, 0.25);
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    border: none;
    padding: 10px 0px;
    max-width: 203px;
    width: 100%;
    text-decoration: none;
}
.without-login-btn a:hover {
    box-shadow: 0 19px 30px -5px rgb(232 0 84 / 50%);
}
.without-login-btn a.offer-sign{
    background: rgba(232, 0, 84, 0.1);
    border: 1px solid #E80054;
    font-weight: 700;
    letter-spacing: unset;
}
.specail-offers-section{
    padding-top: 120px;
}
.special-offer-box{
    background: rgba(66, 85, 183, 0.1);
    border: 1px solid rgba(74, 38, 171, 0.5);
    box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    padding: 40px;
}
.special-offer-box h2{
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 10px;
}
.special-offer-box p{
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #ACA2B4;
    margin: 0;
    padding-bottom: 33px;
    max-width: 472px;
}
.special-offer-box button, .special-offer-box button:hover, .special-offer-box button:focus{
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    max-width: 202px;
    width: 100%;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 9px;
    box-shadow: none;
}
 .modal-backdrop{
    background: rgb(14 13 39 / 80%);
}

.offer-popup .modal-content{
    background: #FFFFFF;
    box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
    border-radius: 10px;
    max-width: 556px;
    margin: 0 auto;
}
.offer-popup .modal-header{
    border: none;
    padding: 40px 40px 0px;
    justify-content: flex-end;
}
.offer-popup .btn-close{
    background: url("../../assets//images/offer/close-icon.svg") center;
    width: 20px;
    height: 20px;
    opacity: unset;
    border-radius: unset;
    margin: 0;
    padding: 0;
}
.offer-popup .modal-body{
    padding: 0px 40px 60px 40px;
    text-align: center;
}
.collect-offer-content h6{
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #0E0D27;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 10px;
}
.collect-offer-content p{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: rgb(14 13 39 / 60%);
    margin: 0;
    padding-bottom: 40px;
}
.collect-offer-content button, .collect-offer-content button:hover, .collect-offer-content button:focus, .collect-offer-content button:active:focus{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    max-width: 228px;
    width: 100%;
    border: none;
    padding: 17.5px 0;
}
.collect-offer-content button:hover {
    box-shadow: 0px 19px 30px -5px rgb(232 0 84 / 50%);
}