.recharged-game .admin-search-wrapper {
  padding-top: 0;
}
.recharged-game-content .wallet-user-recent-game-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.recharged-game-content button.red-btn {
  width: 82px;
}
.recharge-popup-content {
  max-width: 598px;
  width: 100%;
  margin: 0px auto;
}

.recharge-popup-content h5 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 40px;
  text-transform: capitalize;
}
.game-recharge-info {
  background: #f3f3f4;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 598px;
  width: 100%;
  margin: 0px auto;
}
.game-recharge-left img {
  max-width: 100px;
  width: 100%;
  height: 100px;
  border-radius: 10px;
}
.game-recharge-right h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
}
.game-recharge-right p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #2e8be6;
  margin: 0;
  text-align: left;
  display: flex;
  gap: 10px;
}
.total-balance {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px;
  row-gap: 10px;
}
.total-balance h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
}
.total-balance p {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
  gap: 10px;
}
.total-balance p img {
  width: 24px;
  height: 24px;
}
.popup-footer-btn {
  padding-top: 40px;
  padding-bottom: 20px;
  gap: 32px;
  justify-content: center;
}
.recharge-amount-wrapper {
  width: 100%;
  position: relative;
}

.recharge-amount-wrapper input,
.recharge-amount-wrapper input:focus {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(14 13 39 / 20%);
  border-radius: 4px;
  box-shadow: none;
  padding: 20px 25px;
  padding-right: 80px;
  height: 70px;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #0e0d27;
  position: relative;
}

.recharge-amount-show {
  position: absolute;
  right: 25px;
  top: 25px;
}
.recharge-amount-show p {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  color: #0e0d27;
  margin: 0;
}
.recharge-amount-show img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.recharge-amount-wrapper input::placeholder {
  color: rgb(14 13 39 / 20%);
}

.recharge-success-content {
  max-width: 420px;
  width: 100%;
  margin: 0px auto;
}
.recharge-success-content h5 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
  padding: 30px 0px;
}
img.thumb-icon {
  max-width: 80px;
  width: 100%;
  height: 70px;
}
.recharge-success-content .game-recharge-left img {
  max-width: 128px;
  height: 130px;
}
.recharge-success-content .game-recharge-right p {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #2e8be6;
  align-items: center;
}
.recharge-success-content .game-recharge-right img {
  width: 30px;
  height: 30px;
}
.recharge-success-content .popup-red {
  max-width: 100%;
  line-height: 30px;
  letter-spacing: 0.003em;
  margin-top: 40px;
}

/************************** Withdraw request popup ************************/

.with-req-pop p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #0e0d27;
  opacity: 0.6;
  margin: 0;
  padding-bottom: 25px;
}

.with-req-pop span {
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  color: #2e8be6;
}

.with-req-pop h5 {
  padding: 30px 0 10px;
}

.with-req-pop button.popup-red.btn.btn-primary {
  margin-top: 35px;
}
