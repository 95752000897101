.withdraw-page .confirm-btn {
  max-width: 120px;
}

.currency-type {
  max-width: 130px;
  width: 100%;
}

.request-date {
  max-width: 155px;
  width: 100%;
}

.request-date p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(255 255 255 / 60%);
  margin: 0;
  padding-bottom: 3px;
}

.request-date h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.transaction-amt-grid {
  display: flex;
  max-width: 150px;
  width: 100%;
}

.transaction-amt p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(255 255 255 / 60%);
  margin: 0;
  padding-bottom: 3px;
}

.transaction-amt h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}

.withdarw-popup .modal-backdrop {
  background: #0e0d27;
  opacity: 0.8 !important;
}

.withdarw-popup .modal-content {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 850px;
  margin: 0 auto;
}

.withdarw-popup .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}

.withdarw-popup .btn-close {
  background: url("../../../assets/images/offer/close-icon.svg") center;
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
}

.withdarw-popup .modal-body {
  padding: 0px 0px 80px 0px;
  text-align: center;
  color: #0e0d27;
}

.transaction-heading {
  padding: 40px 0;
  max-width: 580px;
  margin: 0px auto;
}

.transaction-heading h6 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 30px;
}

.transaction-heading p {
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  color: #2e8be6;
  margin: 0;
}
.withdraw-popup-fix {
  position: relative;
  max-height: 250px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cashtag-link {
  background: #f3f3f4;
  border-radius: 10px;
  padding: 20px;
  max-width: 410px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cashtag-link h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
}

.cashtag-link span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: rgb(14 13 39 / 50%);
  padding-left: 10px;
}

.link-icon {
  cursor: pointer;
}

.upload-image-box {
  margin: 30px auto;
  max-width: 410px;
  padding: 20px;
  border: 2px dashed #c1c1c2;
  border-radius: 10px;
}

.qr-box {
  margin: 30px auto;
  max-width: 340px;
  padding: 20px;
  border: 1px solid #f3f3f4;
  border-radius: 10px;
}

.qr-box img {
  max-width: 340px;
  width: 100%;
}

.upload-image-box img {
  max-width: 340px;
  width: 100%;
}

.qr-info h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 10px;
}

.qr-info p {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: rgb(14 13 39 / 50%);
  margin: 0;
}

.popup-btn-grid {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 40px;
}

.popup-cancel-btn {
  background: transparent;
  max-width: 190px;
  width: 100%;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #0e0d27;
  padding: 16px 6px;
}

.popup-cancel-btn:hover,
.popup-cancel-btn:focus {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  color: #0e0d27;
}

.popup-confirm-btn {
  max-width: 190px;
  width: 100%;
  border: none;
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 16px 6px;
}

.popup-confirm-btn:hover,
.popup-confirm-btn:focus,
.popup-confirm-btn:active:focus {
  border: none;
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  color: #ffffff;
}


.upload-image-box input {
  display: none;
}

.preview img {
  height: 45px;
  width: 45px;
}

.remove-button {
  top: -22px;
  right: 13px;
  font-size: 10px;
}

.payment-link::placeholder {
  color: rgb(14 13 39 / 50%) !important;
}