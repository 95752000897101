.deposit-popup-input {
  position: relative;
  max-width: 633px;
  margin: 0 auto;
}

/* .deposit-popup-content {
  text-align: unset !important;
} */
.label-input {
  max-width: 633px !important;
}

.deposit-popup-fix {
  position: relative;
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.deposit-popup-input input,
.deposit-popup-input input:hover,
.deposit-popup-input input:focus {
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  box-shadow: none;
  padding: 20px 100px 20px 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(14 13 39 / 30%);
}

.deposit-popup-input input::placeholder {
  color: rgb(14 13 39 / 30%) !important;
}

.deposit-popup-money {
  /* display: flex; */
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 20px;
  right: 50px;
}

.deposit-popup-money-address {
  position: absolute;
  top: 47px;
  right: 50px;

}

.deposit-popup-money span {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  letter-spacing: 0.003em;
  color: #0e0d27;
}

.deposit-popup-transfer {
  padding-top: 50px;
}

.deposit-popup-transfer h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
  padding: 0 106px;
  text-align: start;
  padding-bottom: 30px;
}

.deposit-popup-transfer-box {
  background: #f3f3f4;
  border-radius: 10px;
  max-width: 637px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.deposit-popup-transfer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.deposit-transfer-box-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-bottom: 1px solid rgb(255 255 255 / 90%);
}

.deposit-transfer-box-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 25px;
}

.deposit-transfer-box-bottom p {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 0.003em !important;
  color: #0e0d27 !important;
  opacity: unset !important;
  max-width: 100% !important;
  margin: 0 !important;
  text-align: start !important;
}

.deposit-transfer-box-bottom span {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  letter-spacing: 0.003em;
  color: #2e8be6;
}

.deposit-coin span {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(14 13 39 / 30%);
}

.deposit-cash-app label {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #0e0d27;
}

.deposit-cash-app img {
  padding-right: 8px;
}

/************************** Radio button *****************************/

.deposit-cash-app [type="radio"]:checked,
.deposit-cash-app [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.deposit-cash-app [type="radio"]:checked+label,
.deposit-cash-app [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.deposit-cash-app [type="radio"]:checked+label:before,
.deposit-cash-app [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  border-radius: 100%;
  background: #fff;
}

.deposit-cash-app [type="radio"]:checked+label:after,
.deposit-cash-app [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #e80054;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.deposit-cash-app [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.deposit-cash-app [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.coin-popup-content h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.003em;
  text-transform: uppercase;
  color: #0e0d27;
  opacity: 0.3;
  margin: 40px 0 10px;
}

.new-added-cashtag-step {
  margin-top: 60px;
  margin-bottom: 60px;
}

.withdraw-popup-money span {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #0e0d27;
}

.withdraw-popup-money img {
  width: 30px;
}

.withdraw-popup-money {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.deposit-popup-transfer-box.active {
  background: #e3eefb;
}