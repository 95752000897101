.home {
  background: #0e0d27;
  /* padding-top: 75px; */
}

.hero-banner {
  background: url("../../assets/images/home/banner.png");
  width: 100%;
  background-size: cover !important;
  height: 100%;
  padding-top: 75px;
  padding-bottom: 128px;
  background-repeat: no-repeat !important; 
  background-position: center !important;
}

.page-title {
  padding-bottom: 40px;
}

.page-title h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 20px;
}

.home-left {
  max-width: 540px;
  width: 100%;
}

.home-left h1 {
  font-weight: 700;
  font-size: 62px;
  line-height: 80px;
  color: #ffffff;
  margin: 0;
}

.home-left p {
  font-weight: 400;
  font-size: 32px;
  line-height: 50px;
  color: #aca2b4;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 50px;
}

.home-left button,
.home-left button:hover {
  background: #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #ffffff;
  border: none;
  max-width: 245px;
  width: 100%;
  height: 70px;
}
.home-left button:hover {
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 50%);
}
.home-left button:focus,
.home-left button .btn:focus {
  box-shadow: none;
  background: #e80054;
}

/* ********** Hero Slider ************* */

.hero-slider {
  background: #120f27;
}
.hero-slider:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../../assets/images/home/Subtract.svg);
  top: -94px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
  display: none;
}

.hero-slider {
  position: relative;
}
.hero-slider-content {
  padding-top: 75px;
  padding-bottom: 98px;
}

.hero-slider .slick-prev {
  width: 30px;
  height: 30px;
  background: rgb(232 0 84 / 20%);
  z-index: 8;
  border-radius: 100px;
  color: #e80054;
  left: -60px;
}

.hero-slider .slick-next {
  width: 30px;
  height: 30px;
  background: rgb(232 0 84 / 20%);
  z-index: 8;
  border-radius: 100px;
  color: #e80054;
  right: -60px;
}

.hero-slider .slick-prev::before {
  content: "\f30a";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.hero-slider .slick-next::before {
  content: "\f30b";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

/* .hero-slider .slick-prev,
.hero-slider .slick-next {
  display: none !important;
} */

.hero-slider .slick-dots {
  text-align: center;
  bottom: -48px;
}

.hero-slider .slick-dots li button:before {
  color: #aca2b4;
  opacity: unset;
}

.hero-slider .slick-dots li.slick-active button:before {
  background: #2e8be6;
  color: #2e8be6;
  box-shadow: 0px 0px 10px #2e8be6;
  border-radius: 5px;
  opacity: unset;
  width: 15px;
  height: 3px;
  content: "";
  top: 8px;
}

.hero-slider-tiles {
  padding: 0px 15px;
}

.hero-slider-box {
  background: rgb(46 139 230 / 10%);
  /* box-shadow: 0px 14px 50px -6px rgba(104, 110, 108, 0.3); */
  border-radius: 10px;
  padding: 10px;
  padding-right: 20px;
  border: 1px solid transparent;
  max-width: 100%;
}

.hero-slider-box:hover {
  cursor: pointer;
  border-color: #2e8be6;
}

.hero-slider-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.hero-slider-pic {
  /* max-width: 270px; */
  width: 100%;
  height: 150px;
}

.hero-slider-pic img {
  min-width: 150px;
  width: 100%;
  height: 150px;
  object-fit: cover;
  box-shadow: 0px 14px 50px -6px rgba(104, 110, 108, 0.3);
  border-radius: 9px;
}

.hero-slider-info {
  /* max-width: calc(100% - 290px); */
  width: 70%;
}

.hero-slider-info h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #ffffff;
  padding-bottom: 15px;
}

.hero-slider-btn {
  display: flex;
  gap: 10px;
}

.hero-slider-btn .download-btn,
.hero-slider-btn .download-btn:hover {
  max-width: 80px;
  width: 100%;
  background: #e80054;
  box-shadow: 0px 6px 20px -5px rgba(232, 0, 84, 0.25);
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border: none;
}
.hero-slider-btn .download-btn:focus,
.hero-slider-btn .download-btn .btn:focus {
  box-shadow: none;
  background: #e80054;
}

.hero-slider-btn .info-btn,
.hero-slider-btn .info-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px;
}

/* ******** Why US Section ********** */

.why-us-section {
  padding: 120px 0px;
  position: relative;
  background: url("../../assets/images/home/bg.png");
  background-size: cover;
  height: 100%;
  width: 100%;
}

/* .why-us-section:before {
    background: url("../../assets/images/home/bg.png");
    background-size: cover;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
} */

.section-title {
  display: flex;
  justify-content: space-between;
}
.section-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 75px;
}

.section-title h6,
.popular-section-title h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #2e8be6;
  margin: 0px;
  position: relative;
}

.section-title h6:after,
.popular-section-title h6:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background: #2e8be6;
  top: 40%;
  transform: translate(80%, 0);
}

.section-title a,
.section-title a:hover,
.popular-section-link a,
.popular-section-link a:hover {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
  letter-spacing: 0.003em;
  text-decoration-line: underline;
  color: #e80054;
}

.popular-section-title {
  position: absolute;
}

.popular-section-link {
  /* position: absolute;
  right: 0; */

  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 50px;
}

.our-feature h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
  max-width: 820px;
  padding-top: 30px;
}

.our-feature-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 34px;
  padding-top: 70px;
  padding-bottom: 120px;
}

.our-feature-box h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
  padding-bottom: 10px;
  padding-top: 20px;
}

.our-feature-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #aca2b4;
  margin: 0;
  max-width: 338px;
}

.games-brand-slider {
  padding-top: 50px;
}

.games-brand-slider .slick-prev {
  width: 30px;
  height: 30px;
  background: rgb(232 0 84 / 20%);
  z-index: 8;
  border-radius: 100px;
  color: #e80054;
  left: -60px;
}

.games-brand-slider .slick-next {
  width: 30px;
  height: 30px;
  background: rgb(232 0 84 / 20%);
  z-index: 8;
  border-radius: 100px;
  color: #e80054;
  right: -60px;
}

.games-brand-slider .slick-prev::before {
  content: "\f30a";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.games-brand-slider .slick-next::before {
  content: "\f30b";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.games-brand-tiles {
  padding: 0px 14px;
}

.games-brand-box {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  max-width: 100%;
  display: block !important;
}

.games-brand-box img {
  width: 100%;
  border-radius: 10px;
  height: 365px;
  object-fit: cover;
}

.game-name {
  position: absolute;
  bottom: 10px;
  width: 95%;
  background: rgb(22 17 48 / 90%);
  border-radius: 10px;
  left: 10px;
  padding: 25px 20px;
}

.game-name h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 10px;
}

.game-name p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: #2e8be6;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.game-name img {
  width: 16px;
  height: 12px !important;
}

.game-info {
  position: absolute;
  inset: 0px;
  padding: 30px;
  opacity: 0;
  transition: all 0.5s ease 0s;
  background: rgba(4, 5, 9, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.games-brand-box:hover .game-info {
  opacity: 1;
}

.game-info h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
}

.game-info p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #aca2b4;
  margin: 0;
  padding-top: 10px;
}

.games-brand-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.games-brand-btn button,
.games-brand-btn button:hover {
  background: #e80054;
  box-shadow: 0px 6px 20px -5px rgba(232, 0, 84, 0.25);
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 10px 0px;
  max-width: 165px;
  width: 100%;
}
.games-brand-btn button:hover {
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 50%);
}

.games-brand-btn a,
.games-brand-btn a:hover {
  background: rgb(255 255 255 / 10%);
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  max-width: 115px;
  width: 100%;
  padding: 9px 0px;
}
.games-brand-btn a:hover {
  background: rgb(255 255 255 / 20%);
}

/* *********** Offer Section ************* */
.offer-section {
  background: url("../../assets/images/home/offer-bg.png");
  width: 100%;
  background-size: 100% 100%;
  height: 100%;
  padding: 100px 0px;
  background-repeat: no-repeat;
  background-position: center;
}

.offer-section h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
  max-width: 418px;
}

span.offer-heading {
  display: inline-block;
  color: #e80054;
}

.offer-section p {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #aca2b4;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 350px;
}

.offer-section a,
.offer-section a:hover {
  background: #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  max-width: 245px;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border: none;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.offer-section a:hover {
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 50%);
}

/* ********* Popular Section ********** */
.popular-games-section {
  padding-top: 120px;
  background: #1f1842;
}

.popular-games-content {
  position: relative;
}

.popular-games-content .section-title {
  position: absolute;
  width: 100%;
}

.tab-section ul.nav.nav-tabs {
  max-width: 186px;
  margin: 0px auto;
  border-bottom: none;
}

.tab-section .nav-tabs .nav-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #aca2b4;
  border: none;
  padding: 0;
  margin: 0;
}

.tab-section .nav-tabs li {
  margin-right: 17px;
}

.tab-section .nav-tabs li:last-child {
  margin: 0;
}

.tab-section .nav-tabs .nav-item.show .nav-link,
.tab-section .nav-tabs .nav-link.active {
  color: #ffffff;
  background: none;
  border-bottom: 1px solid #e80054;
}

.popular-tab-content {
  padding-top: 50px;
}

.popular-tab-content img {
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 14px 50px -6px rgba(18, 15, 39, 0.4);
  position: relative;
  height: 222px;
  object-fit: cover;
}
.all-games-content .popular-tab-content img {
  height: 265px;
  object-fit: cover;
}
.popular-big-box img {
  max-width: 580px;
  width: 100%;
  height: 470px;
  object-fit: cover;
}

.popular-badge {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 9;
}

.popular-badge img {
  box-shadow: unset;
  position: unset;
  height: auto;
}

.popular-small-grid {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
}

.popular-small-box,
.popular-big-box,
.popular-grid-box {
  position: relative;
  cursor: pointer;
}

.popular-tab-content .game-info {
  padding: 25px 20px 20px;
}

.popular-tab-content .games-brand-btn button,
.popular-tab-content .games-brand-btn button:hover {
  max-width: 126px;
}
.popular-tab-content .games-brand-btn button:hover {
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 50%);
}

.popular-tab-content .games-brand-btn a,
.popular-tab-content .games-brand-btn a:hover {
  max-width: 80px;
}

.popular-small-box:hover .game-info,
.popular-big-box:hover .game-info,
.popular-grid-box:hover .game-info {
  opacity: 1;
}

.popular-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px 0;
}

.popular-grid-left,
.popular-grid-right {
  width: 48%;
}

.popular-inner-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  gap: 30px;
  padding-top: 30px;
}

.popular-grid-right .popular-inner-grid {
  padding-top: 0;
  padding-bottom: 30px;
}

/* *********  Testimonial *********** */
.testimonial-section {
  padding: 120px 0px;
  background: #1f1842;
}

.testimonial-heading {
  padding-top: 30px;
}

.testimonial-heading h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.testimonial-heading p {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #aca2b4;
  margin: 0;
}
.testimonial-slider {
  padding-top: 60px;
}

.testimonial-slider .slick-prev {
  width: 30px;
  height: 30px;
  background: rgb(232 0 84 / 20%);
  z-index: 8;
  border-radius: 100px;
  color: #e80054;
  left: -60px;
}

.testimonial-slider .slick-next {
  width: 30px;
  height: 30px;
  background: rgb(232 0 84 / 20%);
  z-index: 8;
  border-radius: 100px;
  color: #e80054;
  right: -60px;
}

.testimonial-slider .slick-prev::before {
  content: "\f30a";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.testimonial-slider .slick-next::before {
  content: "\f30b";
  font-family: "Line Awesome Free";
  font-weight: 900;
}
.testimonial-tiles {
  padding: 0px 15px;
}

.testimonial-box {
  background: rgb(255 255 255 / 10%);
  /* box-shadow: 0px 14px 50px -6px rgba(104, 110, 108, 0.3); */
  border-radius: 10px;
  padding: 30px 30px 35px;
  max-width: 100%;
}

.user-info-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.user-img-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-img-box img {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}

.user-img-box h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.star-grid {
  display: flex;
  gap: 5px;
}

.testimonial-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #aca2b4;
  margin: 0;
  max-width: 285px;
}
