.support-box {
  max-width: 360px;
  width: 100%;
  position: fixed;
  right: 9%;
  bottom: 20px;
  height: 400px;
  z-index: 99;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 10px 10px 20px 20px;
}

.user-img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.user-img {
  background: #fff;
  height: 52px;
  width: 52px;
  border-radius: 50%;
  padding: 1px;
}

.support-heading h6 {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.support-title-sec {
  display: flex;
  align-items: center;
  gap: 15px;
}

.support-header {
  padding: 7px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #E80054;
  border-radius: 10px 10px 0px 0px;
  color: white;
  border: 1px solid rgba(74, 38, 171, 0.5);
  border-bottom: none;
}

.cancel-support {
  width: 50px;
  display: flex;
  justify-content: flex-end;
}

.user-message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.user-message-icon {
  width: 27px;
  height: 27px;
  border-radius: 34px;
  background: #E80054;
}

.user-message-icon img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 100%;
}

.message-section p {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: right;
  color: #ACA2B4;
  margin: 0;
  padding-top: 5px;
}

.message-box {
  background: #FFFFFF;
  border-radius: 0px 10px 10px 10px;
  max-width: 256px;
  width: fit-content;
  padding: 10px 15px;
}

.message-box h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0E0D27;
  margin: 0;
  word-break: break-word;
  user-select: text;
}

.admin-message {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.admin-message .message-box {
  background: #e80054;
  border-radius: 10px 10px 0px 10px;

}

.admin-message .message-box h6 {
  color: #FFFFFF;
}

.admin-message .message-section p {
  text-align: left;
}

.support-time {
  text-align: center;
  margin: 5px;
}

.support-time span {
  font-size: 12px;
  color: #8a8686;
  font-weight: 400;
}

.support-input-section {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.support-input .form-group {
  margin-bottom: 0px;
  border: none !important;
}

.support-input .form-group input {
  border: 1px solid rgba(74, 38, 171, 0.5);
  border-top: 1px solid #ACA2B4 !important;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;
  height: 50px;
  padding-right: 50px;
  color: #ACA2B4;
  background: transparent;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  margin-left: -1px;

}

.support-input .form-group input:focus {
  outline: none;
  box-shadow: none;
  color: #495057 !important;
  background-color: white !important;
}

.send-support {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.send-support button,
.send-support button:hover,
.send-support button:focus,
.send-support .btn:active:focus {
  background-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px;
  padding-right: 20px;
  padding-bottom: 12px;
}

.support-body {
  padding: 20px 10px 20px 0px;
  border: 1px solid rgba(74, 38, 171, 0.5);
  border-top: none;
  border-bottom: none;
}

.support-section {
  height: 255px;
  overflow: auto;
  padding-right: 10px;
  padding-left: 20px;
}

.support-section::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.support-section::-webkit-scrollbar-track {
  background: #CBC3CC;
}

.support-section::-webkit-scrollbar-thumb {
  background-color: #E80054;
}

.cancel-support img {
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.support-icon span {
  background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  color: #fff;
  line-height: 56px;
  text-align: center;
  position: fixed;
  bottom: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

.support-icon span:hover {
  transform: translatey(-10px);
}


.support-chat-wrapper {
  position: absolute;
  right: 60px;
  z-index: 999;
}

.chat_spinner_container {
  display: flex;
  justify-content: center;
}