@media screen and (max-width: 1300px) {
  .wallet-user-level {
    flex-wrap: wrap;
  }

  .wallet-user-level-left {
    max-width: 100%;
    border-radius: 9px 9px 0 0;
    padding: 20px 30px 20px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0px 15px;
  }

  .auth-page {
    padding: 60px 0;
  }



  .header-menu-section {
    max-width: calc(100% - 400px);
  }

  .top-footer {
    justify-content: center;
    row-gap: 22px;
  }

  .main-footer .logo {
    margin-top: 40px;
  }

  .hero-banner {
    padding: 60px 0;
  }

  .home-left h1 {
    font-size: 58px;
  }

  .page-title {
    padding-bottom: 30px;
  }

  .why-us-section {
    padding: 60px 0;
  }

  .game-name {
    width: 93%;
  }

  .our-feature-grid {
    padding-bottom: 60px;
  }

  .offer-section {
    padding: 60px 0;
  }

  .popular-games-section {
    padding: 60px 0 0;
  }

  .popular-big-box img {
    max-width: 480px;
  }

  .popular-tab-content .game-info {
    padding: 15px;
  }

  .popular-tab-content .games-brand-btn button,
  .popular-tab-content .games-brand-btn button:hover {
    max-width: 90px;
  }

  .testimonial-slider .slick-next,
  .testimonial-slider .slick-prev {
    display: none !important;
  }

  .games-brand-slider .slick-prev,
  .games-brand-slider .slick-next {
    display: none !important;
  }

  .hero-slider .slick-prev,
  .hero-slider .slick-next {
    display: none !important;
  }

  .testimonial-box {
    padding: 30px 15px 35px;
  }

  .testimonial-section {
    padding: 60px 0px;
  }

  .games-brand-box .game-info {
    padding: 15px;
  }

  .games-brand-box .game-info p {
    padding-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    margin-bottom: 40px;
  }

  .games-brand-btn button,
  .games-brand-btn button:hover {
    max-width: 140px;
  }

  /* Game page */
  .game-banner {
    padding: 60px 0;
  }

  .games-tab-section {
    padding: 60px 0;
  }

  .game-left h1 {
    font-size: 58px;
  }

  .banner-left h1 {
    font-size: 58px;
  }

  .games-tab-grid ul.nav.nav-tabs {
    flex-wrap: nowrap;
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .games-tab-grid .nav-tabs .nav-link {
    width: 115px;
    height: 110px;
  }

  .games-tab-grid .nav-tabs .nav-link h6 {
    white-space: nowrap;
  }

  .offer-banner {
    padding: 60px 0;
  }

  .offers-section {
    padding: 60px 0;
  }

  .offers-section .slick-prev,
  .offers-section .slick-next {
    display: none !important;
  }

  .specail-offers-section {
    padding-top: 60px;
  }

  .brand-detail-section {
    padding: 60px 0;
  }

  .brand-detail-tabs {
    padding: 60px 0;
  }

  .brand-detail-tabs .games-tab-grid ul.nav.nav-tabs {
    gap: 26px;
  }

  /* .all-games-content .popular-tab-content .popular-small-box{
        max-width: 22%;
        width: 100%;
    } */

  .privacy-policy,
  .terms {
    padding: 60px 0;
  }

  .legal-page-heading {
    margin-bottom: 30px;
  }

  .contact-page {
    padding: 60px 0;
  }

  .contact-left {
    max-width: calc(100% - 550px);
  }

  .contact-right {
    max-width: 500px;
  }

  .get-in-touch h1 {
    font-size: 58px;
  }

  .get-in-touch p {
    max-width: 100%;
  }

  .support-box {
    right: 15px;
    bottom: 15px;
  }

  .search-result-section {
    max-width: 500px;
    width: 500px;
  }

  .search-result-box {
    padding: 20px;
  }

  .download-games-slider .slick-prev,
  .download-games-slider .slick-next {
    display: none !important;
  }

  .download-games-slider .slick-dots {
    bottom: -90px;
  }

  .download-games-slider .slick-dots li {
    max-width: 100px;
    height: 70px;
  }

  .download-games-slider .slick-dots li img {
    max-width: 100px;
    height: 70px;
  }

  .game-download-page .brand-detail-section {
    padding-top: 150px;
  }

  .download-game-tiles img {
    height: 420px;
  }

  .relevent-game-content {
    padding-bottom: 60px;
  }

  .about-banner {
    padding: 60px 0;
  }

  .about-feature-section {
    padding: 60px 0;
  }

  .distributor-top-section {
    padding-top: 60px;
  }

  .become-distributor-content {
    padding-top: 60px;
  }

  .warning-section {
    padding-bottom: 60px;
  }

  .distributor-popup .modal-content {
    max-width: 650px;
  }

  .distributor-popup .modal-header {
    padding: 15px 15px 0px;
  }

  .distributor-popup .modal-body {
    padding: 0px 15px 40px 15px;
  }

  .play-game-right {
    max-width: calc(100% - 600px);
  }

  .pg-top-left-pic {
    display: none;
  }

  .pg-bottom-left-pic {
    display: none;
  }

  /* .key-points-grid li{
        max-width: 100%;
        width: 31.3%;
        padding: 30px;
    } */
  .key-points-grid li {
    max-width: 30%;
    padding: 30px;
  }

  .key-points-grid li:last-child {
    max-width: 63%;
  }

  .relevent-game-content .popular-small-grid {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }

  .custom-arrow {
    display: none;
  }

  /* Client Page Responsive CSS */
  .client-profile-page .container {
    padding-right: 30px;
  }

  .wallet-page {
    max-width: 100%;
  }

  .wallet-page-content .wallet-balance-menu {
    display: none;
  }

  span.wallet-section {
    cursor: pointer;
  }

  .wallet-wrapper.offcanvas {
    max-width: 285px;
    width: 100%;
  }

  .wallet-wrapper.offcanvas.offcanvas-end {
    top: 70px;
  }

  .wallet-transaction-card-scroll {
    height: calc(100vh - 520px);
  }

  .recent-game-detail {
    max-width: calc(100% - 20px);
    bottom: 0;
  }

  .recent-game_box img {
    max-width: 100%;
    height: 100%;
  }

  .recent-game-detail-bottom {
    padding: 10px;
  }

  .notification-wrapper.offcanvas {
    max-width: 400px;
    padding: 20px;
  }

  .notification-wrapper.offcanvas.offcanvas-end:after {
    display: none;
  }

  .notification-list {
    padding-right: 10px;
  }

  .notfication-card-body {
    padding: 12px;
  }

  .notfication-card-header {
    padding: 12px;
  }

  .notfication-card-footer {
    padding: 0 12px 12px;
  }

  /* *************************  Setting Page ************************ */
  .setting-page-detail {
    flex-wrap: wrap;
    width: 100%;
  }

  .setting-page-detail-left {
    width: 100%;
  }

  .setting-profile {
    margin: 0 auto;
  }

  .setting-page-detail-right {
    max-width: 100%;
    padding-top: 50px;
  }

  .setting-qr-code p {
    line-height: 20px;
  }

  .my-game-page .wallet-user-recent-game-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .admin-section-title button {
    font-size: 18px;
    gap: 5px;
    height: 50px;
    font-weight: 500;
    max-width: 230px;
    border-radius: 10px;
  }

  .admin-section-title button img {
    width: 15px;
    height: 15px;
  }

  .game-request-popup .modal-header {
    padding: 20px 20px 0;
  }

  .game-request-popup .btn-close {
    width: 15px;
    height: 15px;
  }

  .game-request-heading h2 {
    font-size: 28px;
    line-height: 30px;
  }

  .game-request-search .admin-search-wrapper {
    padding: 15px 0;
  }

  .game-request-wrapper {
    padding: 0 30px 15px 30px;
  }

  .game-request-list .wallet-user-recent-game-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-right: 15px;
    gap: 15px;
  }

  .select-game-request {
    right: 10px;
  }

  .select-game-request .form-check-input,
  .select-game-request .form-check-input:checked[type="checkbox"] {
    width: 20px;
    height: 20px;
  }

  .game-request-list {
    height: calc(100vh - 265px);
  }

  .game-request-popup .popup-footer-btn {
    padding: 15px 0;
    border-radius: 10px;
  }

  .game-request-popup .popup-black,
  .game-request-popup .popup-red {
    max-width: 200px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
    padding: 11.5px 0;
  }

  .recharged-game-content .wallet-user-recent-game-grid,
  .redeemed-game-content .wallet-user-recent-game-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }

  .wallet-info button {
    max-width: 200px;
    font-size: 18px;
    gap: 5px;
    height: 50px;
    font-weight: 500;
    border-radius: 10px;
  }

  .recharged-game-content button.red-btn,
  .redeemed-game-content button.red-btn {
    width: 75px;
  }

  .admin-table .table thead th,
  .admin-table .table tbody td {
    padding: 10px 20px;
  }

  .coin-popup .modal-content {
    max-width: 700px;
  }

  .coin-popup .modal-header {
    padding: 20px 20px 0px;
  }

  .coin-popup .btn-close {
    width: 15px;
    height: 15px;
  }

  .coin-popup-content {
    max-width: 500px;
    margin: 0px auto;
    padding: 0px 0px 40px;
  }

  .coin-popup-content h1 {
    padding-bottom: 15px;
  }

  .deposit-popup-input input,
  .deposit-popup-input input:hover,
  .deposit-popup-input input:focus {
    padding: 10px 100px 10px 10px;
    height: 50px;
  }

  .deposit-popup-money {
    top: 10px;
  }

  .deposit-popup-money-address {
    top: 35px;
  }

  .deposit-popup-transfer {
    padding-top: 30px;
  }

  .deposit-popup-transfer h4 {
    text-align: center;
    padding: 0;
    padding-bottom: 20px;
  }

  .coin-popup-content h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .withdraw-popup-money {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 991px) {
  .header-menu {
    position: absolute;
    right: 20px;
    width: unset;
    max-width: unset;
  }

  .header-menu-section {
    max-width: calc(100% - 530px);
  }

  .auth-box {
    margin-right: 50px;
  }

  .header-menu #responsive-navbar-nav {
    background: #121337;
    height: 100%;
    padding: 0;
    position: fixed;
    left: -315px;
    top: 0;
    transition: all 0.3s ease 0s;
    max-width: 315px;
    width: 100%;
    z-index: 999999;
    box-shadow: 0px 4px 31px rgb(35 40 59 / 10%);
  }

  .header-menu .navbar-collapse.collapse.show {
    left: 0 !important;
  }

  .navbar-nav {
    padding: 20px;
  }

  .header-menu .navbar-expand-lg .navbar-nav .active {
    border: none;
    color: #e80054;
    font-weight: 700;
  }

  .header-menu .navbar-expand-lg .navbar-nav .nav-link {
    padding: 15px 10px;
    border-bottom: 1px solid #2a294c;
    border-radius: 0;
    font-size: 16px;
  }

  .auth-box a {
    font-size: 14px;
    height: 35px;
    max-width: 90px;
    border-radius: 10px;
  }

  .user-loggedin {
    margin-right: 110px;
  }

  .popular-big-box img {
    max-width: 460px;
  }

  .games-tab-grid .nav-tabs .nav-link {
    padding: 10px 20px;
    width: 100px;
    height: 100px;
  }

  .contact-left {
    max-width: calc(100% - 350px);
  }

  .contact-right {
    max-width: 300px;
  }

  .contact-box {
    padding: 20px;
  }

  .contact-box .form-group {
    margin-bottom: 10px;
  }

  .contact-box textarea {
    height: 80px;
  }

  .contact-box .s-btn.btn.btn-primary {
    max-width: 100%;
    padding: 8px 20px;
    border-radius: 10px;
    font-weight: 600;
  }

  .follow-us ul {
    flex-wrap: wrap;
    gap: 30px;
  }

  .download-games-slider h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .download-game-tiles img {
    height: 350px;
  }

  .distributor-popup-content h6 {
    font-size: 24px;
    line-height: 30px;
  }

  .distributor-popup-form {
    padding-top: 20px;
  }

  .distributor-popup-form .form-group {
    margin-bottom: 10px;
  }

  .distributor-popup-form .form-label {
    margin-bottom: 5px;
  }

  .distributor-popup-form .form-control {
    height: 40px;
  }

  .distributor-popup-form textarea.form-control {
    height: 100px;
  }

  .distributor-popup-form .s-btn.btn.btn-primary {
    padding: 10px 40px;
    border-radius: 10px;
    font-size: 16px;
  }

  .play-game-left {
    max-width: 475px;
  }

  /* .play-game-right {
    max-width: calc(100% - 550px);
  }
  .hero-slider-info {
    max-width: calc(100% - 130px);
  } */
  .admin-header .header-menu {
    display: block;
  }

  .admin-wrapper .admin-menu {
    display: none;
  }

  .admin-container {
    max-width: 100%;
    margin-left: 0;
  }

  .admin-header .header-menu #responsive-navbar-nav {
    background: transparent;
  }

  .admin-menu {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
  }

  .cash-code-left span {
    padding-left: 10px;
  }

  /* *************************  Setting Page ************************ */

  .setting-page-detail-right h2 {
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 10px;
  }

  .setting-detail-name {
    padding-bottom: 10px;
  }

  .setting-detail-mail {
    padding-bottom: 10px;
  }

  .setting-detail-number {
    padding-bottom: 35px;
  }

  .setting-payment-info {
    padding-bottom: 35px;
  }

  .setting-payment-info p {
    padding-bottom: 15px;
  }

  .setting-payment {
    padding-bottom: 10px;
  }

  .setting-password-info {
    padding-bottom: 35px;
  }

  .setting-password {
    padding-bottom: 10px;
  }

  .setting-delete-text p {
    font-size: 14px;
    line-height: 24px;
  }

  .transparent-btn button,
  .transparent-btn button:hover,
  .transparent-btn button:focus {
    font-size: 14px;
    width: 160px;
    height: 40px;
    border-radius: 10px;
  }

  .red-btn button,
  .red-btn button:hover,
  .red-btn button:focus {
    font-size: 14px;
    width: 160px;
    height: 40px;
    border-radius: 10px;
  }

  .setting-qr-code p {
    padding-bottom: 0;
  }

  .setting-button {
    padding-top: 35px;
  }

  .coin-popup .modal-content {
    max-width: 600px;
  }

  .page-header {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .coin-popup-content h1 {
    font-size: 28px;
    line-height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .transaction-heading h6 {
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 0px;
  }

  .auth-page {
    padding: 30px 0;
  }

  .auth-box .btn {
    margin: 0px 5px;
  }

  .header-menu-section {
    max-width: calc(100% - 500px);
  }

  .user-loggedin:before {
    content: unset;
  }

  .socail-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .socail-links ul {
    width: 100%;
    justify-content: center;
  }

  .contact-us h6 {
    padding-top: 30px;
    padding-bottom: 5px;
  }

  .copyright-text p {
    padding-top: 15px;
    text-align: center;
  }

  .page-title h6 {
    font-size: 14px;
  }

  .home-left {
    max-width: 430px;
  }

  .home-left h1 {
    font-size: 46px;
    line-height: 60px;
  }

  .home-left p {
    font-size: 25px;
    padding: 30px 0;
  }

  .home-left button,
  .home-left button:hover {
    max-width: 180px;
    height: 60px;
    font-weight: 600;
    border-radius: 10px;
  }

  .our-feature h2 {
    font-size: 28px;
  }

  .our-feature-box h6 {
    font-size: 16px;
  }

  .our-feature-box p {
    font-size: 14px;
  }

  .game-name {
    width: 90%;
    padding: 10px;
  }

  .offer-section h2 {
    font-size: 28px;
  }

  span.offer-heading {
    display: unset;
  }

  .offer-section p {
    font-size: 20px;
    max-width: 315px;
    padding: 20px 0;
  }

  .offer-section a,
  .offer-section a:hover {
    max-width: 180px;
    height: 60px;
    font-weight: 600;
    border-radius: 10px;
  }

  .tab-section ul.nav.nav-tabs {
    max-width: 530px;
    display: flex;
    justify-content: flex-end;
    margin: unset;
  }

  .popular-tab-content img {
    height: 150px;
    object-fit: cover;
  }

  .popular-grid-left,
  .popular-grid-right {
    width: 47%;
  }

  .popular-big-box img {
    max-width: 350px;
  }

  .popular-tab-content .game-info {
    padding: 10px;
  }

  .games-brand-btn {
    bottom: 5px;
  }

  .popular-badge img {
    height: unset;
  }

  .game-info h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .game-info p {
    line-height: 24px;
  }

  .popular-tab-content .games-brand-btn {
    gap: 5px;
  }

  .popular-tab-content .games-brand-btn button,
  .popular-tab-content .games-brand-btn button:hover {
    max-width: 130px;
    padding: 5px 0px;
  }

  .popular-tab-content .games-brand-btn a,
  .popular-tab-content .games-brand-btn a:hover {
    max-width: 130px;
    padding: 4px 0px;
  }

  .game-left {
    max-width: 430px;
  }

  .game-left h1 {
    font-size: 46px;
    line-height: 60px;
  }

  .games-tab-grid .nav-tabs .nav-link {
    padding: 10px 20px;
    width: 100px;
    height: 100px;
  }

  .games-tab-grid .nav-tabs .nav-link h6 {
    gap: 5px;
  }

  .games-tab-grid .nav-tabs .nav-link img {
    max-width: 30px;
  }

  .brand-game-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .banner-left {
    max-width: 430px;
  }

  .banner-left h1 {
    font-size: 46px;
    line-height: 60px;
  }

  .banner-left p {
    font-size: 25px;
  }

  .offer-popup .modal-header {
    padding: 30px 30px 0px;
  }

  .offer-popup .modal-body {
    padding: 0px 30px 30px 30px;
  }

  .collect-offer-content button,
  .collect-offer-content button:hover,
  .collect-offer-content button:focus {
    max-width: 180px;
    font-weight: 600;
    border-radius: 10px;
  }

  .collect-offer-content p {
    padding-bottom: 30px;
  }

  .brand-detail-section button {
    max-width: 180px;
    height: 60px;
    font-weight: 600;
    border-radius: 10px;
    padding: 0;
  }

  .privacy-policy,
  .terms {
    padding: 30px 0;
  }

  .legal-page-heading {
    margin-bottom: 15px;
  }

  .legal-page-heading h1 {
    font-size: 24px;
  }

  .legal-content-wrapper {
    gap: 15px;
  }

  .legal-paragraph {
    gap: 15px;
  }

  .legal-content-body {
    gap: 30px;
  }

  .get-in-touch h1 {
    font-size: 46px;
    line-height: 60px;
  }

  .page-title {
    padding-bottom: 20px;
  }

  .download-games-slider .slick-dots li {
    max-width: 60px;
    height: 40px;
    margin: 0 5px;
  }

  .download-games-slider .slick-dots li img {
    max-width: 60px;
    height: 40px;
  }

  .download-games-slider .slick-dots {
    bottom: -50px;
  }

  .game-download-page .brand-detail-section {
    padding-top: 90px;
  }

  .play-game-left {
    max-width: 100%;
  }

  .play-game-right {
    display: none;
  }

  .key-points-grid li {
    max-width: 100%;
    padding: 20px;
    height: 125px;
  }

  .key-points-grid li:last-child {
    max-width: 100%;
  }

  .pg-left-content button,
  .pg-left-content button:hover {
    max-width: 180px;
    height: 60px;
    font-weight: 600;
    border-radius: 10px;
    padding: 18px;
  }

  .become-distributor-banner {
    padding: 60px 0px 60px 60px;
  }

  .already-distributor p {
    max-width: 500px;
    margin: 0px auto;
    text-align: center;
  }

  .become-distributor-banner button,
  .become-distributor-banner button:hover,
  .become-distributor-banner button:focus {
    max-width: 180px;
    height: 60px;
    font-weight: 600;
    border-radius: 10px;
    padding: 15px;
  }

  .relevent-game-content .popular-small-grid {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }

  .wallet-user-recent-game-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .recent-game_box img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .recent-game-detail {
    max-width: calc(100% - 20px);
    bottom: 0;
  }

  .setting-profile {
    width: 150px;
    height: 150px;
  }

  .setting-page-detail {
    padding: 30px;
  }

  .setting-camera {
    right: 5px;
  }

  .circle img {
    max-width: 150px;
    height: 150px;
  }

  .delete-popup-content h6 {
    max-width: 350px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 30px;
  }

  .delete-popup-footer .btn-secondary {
    font-size: 14px;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
  }

  .delete-popup-footer .btn-primary {
    font-size: 14px;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
  }

  .coin-popup .modal-content {
    max-width: 600px;
  }

  .delete-popup .modal-header {
    padding: 20px 20px 0px;
  }

  .delete-popup-content img {
    width: 60px;
  }

  .delete-popup-footer {
    padding-bottom: 40px;
  }

  .game-box-btn .transparent-btn button,
  .game-box-btn .transparent-btn button:hover,
  .game-box-btn .transparent-btn button:focus {
    font-size: 14px;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
  }

  .game-box-btn .red-btn button,
  .game-box-btn .red-btn button:hover,
  .game-box-btn .red-btn button:focus {
    font-size: 14px;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
  }

  .coin-popup .btn-close {
    width: 15px;
    height: 15px;
  }

  .game-box-left img {
    width: 65px;
  }

  .social-card-content,
  .backsite-btn {
    margin-top: 20px;
  }

  .social-card-heading {
    padding-top: 15px;
  }

  .social-card-heading h1 {
    font-size: 14px;
    font-weight: 500;
  }

  .social-card-share {
    padding-bottom: 15px;
  }

  .coin-popup-content h3 {
    font-size: 14px;
    line-height: 14px;
  }
}

@media screen and (max-width: 767px) {
  .search-wrapper {
    display: none;
  }

  .auth-box {
    margin-right: 20px;
    max-width: 230px;
  }

  .hero-banner {
    padding: 30px 0;
    background: url("./assets/images/home/bg.png") #0e0d27;
  }

  .offer-section {
    background: url("./assets/images/home/bg.png") #0e0d27;
    padding: 30px 0;
  }

  .game-banner {
    padding: 30px 0;
    background: url("./assets/images/home/bg.png") #0e0d27;
  }

  .game-left h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .games-tab-section {
    padding: 30px 0;
  }

  .all-games-content .popular-tab-content {
    padding-top: 30px;
  }

  .offer-banner {
    padding: 30px 0;
    background: url("./assets/images/home/bg.png") #0e0d27;
  }

  .offers-section {
    padding: 30px 0;
  }

  .collect-offer-content img {
    width: 60px;
  }

  .collect-offer-content p {
    padding-bottom: 20px;
  }

  .collect-offer-content button,
  .collect-offer-content button:hover,
  .collect-offer-content button:focus {
    max-width: 140px;
    padding: 12.5px 0;
  }

  .special-offer-box {
    padding: 20px;
  }

  .special-offer-box h2 {
    font-size: 26px;
  }

  .special-offer-box p {
    line-height: 24px;
    padding-bottom: 25px;
    max-width: 100%;
  }

  .special-offer-box button,
  .special-offer-box button:hover,
  .special-offer-box button:focus {
    max-width: 100%;
    font-weight: 400;
    font-size: 14px;
    padding: 12px;
  }

  .without-login,
  .with-login {
    padding: 25px 15px;
  }

  .without-login-btn a,
  .without-login-btn a:hover {
    max-width: 100%;
  }

  .brand-banner-image {
    padding-top: 0;
  }

  .brand-banner-image img {
    height: 300px;
    object-fit: cover;
  }

  .brand-logo img {
    height: 40px;
    width: 40px;
    object-fit: unset;
  }

  .brand-info-section {
    bottom: 10px;
    padding: 0px 10px;
  }

  .brand-info-grid {
    row-gap: 15px;
  }

  .brand-info-left {
    gap: 10px;
    max-width: 100%;
  }

  .brand-info-name h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .brand-info-right {
    max-width: 100%;
  }

  .dropdown-area .btn-primary {
    padding: 5px;
    font-size: 14px;
    gap: 5px;
  }

  .dropdown-area .btn-primary img {
    width: 25px;
    height: 25px;
  }

  .brand-detail-section {
    padding: 30px 0;
  }

  .brand-detail-section h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .brand-detail-section button {
    max-width: 140px;
    height: 50px;
    font-size: 16px;
  }

  .brand-detail-tabs {
    padding: 30px 0;
  }

  .legal-paragraph p {
    font-size: 15px;
    line-height: 24px;
  }

  .legal-paragraph li {
    font-size: 15px;
    line-height: 24px;
  }

  .contact-page {
    padding: 30px 0;
    background-size: cover;
  }

  .contact-left,
  .contact-right {
    max-width: 100%;
  }

  .get-in-touch h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .get-in-touch p {
    font-size: 14px;
    line-height: 24px;
    padding: 20px 0;
  }

  .get-in-touch {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .follow-us ul {
    gap: 10px;
  }

  .contact-grid {
    gap: 30px;
  }

  .follow-us a img {
    width: 25px;
    height: 25px;
  }

  .home-left {
    max-width: 100%;
  }

  .our-feature-box p {
    max-width: 100%;
  }

  .our-feature-grid {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    padding-top: 30px;
  }

  .our-feature-box {
    text-align: center;
  }

  .our-feature-box img {
    width: 100px;
  }

  .about-feature-section .our-feature-box p {
    max-width: 100%;
  }

  .download-game-tiles img {
    height: 250px;
  }

  .download-games-slider h2 {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 20px;
  }

  .game-download-page .brand-detail-section {
    padding-top: 75px;
  }

  .brand-detail-section p {
    padding: 15px 0;
    padding-bottom: 30px;
  }

  .distributor-popup .modal-content {
    max-width: 100%;
  }

  .distributor-popup-form {
    max-width: 350px;
  }

  .distributor-popup-content h6 {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 5px;
  }

  .distributor-popup-content p {
    font-size: 14px;
    line-height: 20px;
  }

  .distributor-popup .modal-header {
    padding: 10px 15px 0px;
  }

  .distributor-popup .modal-body {
    padding: 0px 15px 15px;
  }

  .distributor-popup-form textarea.form-control {
    height: 80px;
  }

  .distributor-popup-form .s-btn.btn.btn-primary {
    padding: 5px 40px;
    font-size: 14px;
    margin-top: 0;
  }

  .about-banner {
    padding: 30px 0;
    background: url("./assets/images/home/bg.png") #0e0d27;
  }

  .about-feature-section {
    padding: 30px 0;
  }

  .distributor-top-section {
    padding-top: 30px;
  }

  .banner-left {
    margin: 0px auto;
  }

  .pg-left-content h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .pg-left-content p {
    font-size: 14px;
  }

  .pg-tags {
    gap: 25px;
    row-gap: 15px;
  }

  .manage-sytem h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .manage-sytem p {
    font-size: 20px;
    line-height: 35px;
  }

  .key-points-grid li {
    font-size: 16px;
  }

  .become-distributor-section {
    padding-top: 30px;
  }

  .become-distributor-content {
    padding-top: 30px;
  }

  .become-distributor-banner {
    background: url("./assets/images/home/bg.png") #0e0d27;
    padding: 30px 20px;
    border-radius: 10px;
  }

  .become-distributor-banner h2 {
    font-size: 28px;
    line-height: 40px;
    padding-bottom: 10px;
  }

  .become-distributor-banner p {
    max-width: 100%;
    font-size: 20px;
    padding-bottom: 30px;
  }

  .warning-section {
    padding-top: 240px;
  }

  .popular-small-grid {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }

  .popular-inner-grid {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }

  .copyright-text p {
    font-size: 14px;
  }

  .game-request-list .recent-game_box img {
    height: 140px;
  }

  .recent-game-detail-bottom img,
  .game-request-list .recent-game-detail-bottom img {
    height: 100%;
  }

  /* ***************************** Setting page **************************** */
  .setting-page-detail {
    padding: 15px;
    padding-bottom: 30px;
  }

  .setting-page-detail-right {
    padding-top: 30px;
  }

  .setting-name {
    width: 100%;
  }

  .setting-detail-name {
    flex-wrap: wrap;
    gap: 10px;
  }

  .setting-payment {
    flex-wrap: wrap;
    gap: 10px;
  }

  .setting-payment-left {
    width: 100%;
  }

  .setting-payment-right {
    width: 100%;
  }

  .setting-delete {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-end;
  }

  .setting-delete-text {
    width: 100%;
  }

  .setting-delete-btn {
    max-width: 150px;
    width: 100%;
  }

  .setting-delete-btn button {
    width: 100%;
    max-width: 100%;
  }

  .transparent-btn,
  .red-btn {
    max-width: 120px;
    width: 100%;
  }

  .transparent-btn button,
  .transparent-btn button:hover,
  .transparent-btn button:focus {
    width: 100%;
    max-width: 100%;
    height: 40px;
    font-weight: 500;
    border-radius: 10px;
    font-size: 14px;
  }

  .red-btn button,
  .red-btn button:hover,
  .red-btn button:focus {
    width: 100%;
    max-width: 100%;
    height: 40px;
    font-weight: 500;
    border-radius: 10px;
    font-size: 14px;
  }

  .setting-delete-account {
    padding: 20px;
  }

  .wallet-user-level {
    flex-wrap: wrap;
  }

  .wallet-user-level-left {
    max-width: 100%;
    border-radius: 9px 9px 0 0;
    padding: 20px 30px 20px;
  }

  .wallet-user-level-right {
    padding: 20px;
  }

  .wallet-user-recent-game-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .recent-game_box img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .coin-popup .modal-dialog {
    max-width: 100%;
  }

  .coin-popup .modal-content {
    max-width: 600px;
  }

  .coin-popup-content h1 {
    font-size: 26px;
    line-height: 30px;
  }

  .coin-popup-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .brand-name span {
    display: none;
  }

  .brand-name {
    max-width: 40px;
  }

  .admin-header {
    padding: 15px;
  }

  .admin-header .user-loggedin {
    margin-right: 70px;
    max-width: 220px;
  }

  .admin-header .header-menu #responsive-navbar-nav {
    max-width: 270px;
  }

  .client-profile-page .container {
    padding-right: 15px;
  }

  .my-game-page .wallet-user-recent-game-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .admin-section-title h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .admin-section-title button img {
    display: none;
  }

  .admin-section-title button {
    font-size: 12px;
    height: 40px;
    max-width: 130px;
    padding: 9px;
  }

  .game-info-name {
    padding: 40px 20px;
  }

  .game-info-name h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .game-info-popup .btn-close {
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    right: 20px;
    top: 20px;
  }

  .game-info-popup .modal-body {
    padding: 20px;
  }

  .game-info-right p {
    font-size: 12px;
    font-weight: 500;
  }

  .popup-footer-btn {
    gap: 15px;
    padding-top: 20px;
    padding-bottom: 0;
  }

  .popup-black,
  .popup-red {
    font-weight: 500;
    max-width: 150px;
    padding: 6.5px 0;
    border-radius: 10px;
    font-size: 14px;
  }

  .admin-tab-section .nav-tabs li {
    margin-right: 18px;
  }

  .wallet-page,
  .admin-content-wrapper {
    padding: 20px 0;
  }

  .admin-section-title {
    padding-bottom: 20px;
  }

  .game-request-wrapper {
    padding: 0 15px 15px;
  }

  .game-request-heading h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .game-request-heading p {
    font-size: 14px;
    line-height: 20px;
  }

  .game-request-popup .modal-fullscreen {
    width: 90vw;
  }

  .game-request-list {
    height: calc(100vh - 240px);
  }

  .game-request-list .wallet-user-recent-game-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .game-request-list .recent-game-detail-left p {
    font-weight: 500;
    font-size: 10px;
    line-height: 6px;
  }

  .game-request-list .recent-game-detail-bottom {
    padding: 6px;
  }

  .game-request-list .recent-game-detail {
    max-width: calc(100% - 10px);
  }

  .select-game-request .form-check-input,
  .select-game-request .form-check-input:checked[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .select-game-request {
    right: 5px;
    top: 5px;
  }

  .game-request-popup .popup-footer-btn {
    gap: 15px;
  }

  .game-request-popup .popup-black,
  .game-request-popup .popup-red {
    max-width: 165px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px;
    padding: 6.5px 0;
  }

  .game-request-popup .popup-black {
    max-width: 80px;
  }

  .page-header-title h2 {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 15px;
  }

  .recharged-game-content .wallet-user-recent-game-grid,
  .redeemed-game-content .wallet-user-recent-game-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .wallet-info button {
    max-width: 145px;
    font-size: 14px;
  }

  .process-popup .modal-body {
    padding: 0px 20px 20px;
  }

  .process-popup .modal-header {
    padding: 20px 20px 0;
  }

  .process-popup .btn-close {
    width: 15px;
    height: 15px;
  }

  .recharge-popup-content h5,
  .redeem-popup-content h5 {
    font-size: 24px;
    line-height: 20px;
    padding-bottom: 30px;
  }

  .redeem-popup-content .total-balance p {
    font-size: 26px;
    line-height: 30px;
  }

  .redeem-popup-content .total-balance p img {
    width: 20px;
    height: 20px;
  }

  .game-recharge-info {
    padding: 10px;
    gap: 15px;
  }

  .game-recharge-right h6 {
    font-size: 16px;
    line-height: 22px;
  }

  .total-balance h6 {
    font-size: 14px;
  }

  .total-balance {
    padding: 20px 0px;
  }

  .recharge-amount-wrapper input,
  .recharge-amount-wrapper input:focus {
    padding: 10px;
    height: 50px;
  }

  .recharge-amount-show {
    top: 15px;
  }

  .recharge-amount-show img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .transaction-page .admin-tab-section ul.nav.nav-tabs {
    border-bottom: none;
    flex-wrap: nowrap;
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .transaction-page .admin-tab-section .nav-tabs li {
    white-space: nowrap;
  }

  .transaction-page .admin-tab-section .nav-tabs .nav-link {
    padding: 5px 10px;
  }

  .transaction-page .admin-tab-section .nav-tabs .nav-item.show .nav-link,
  .transaction-page .admin-tab-section .nav-tabs .nav-link.active {
    background: rgb(232 0 84 / 80%);
    border-radius: 8px;
    box-shadow: 0 14px 50px -6px rgb(232 0 84 / 70%);
    color: #fff;
    border-bottom: none;
  }

  .backsite-btn a {
    max-width: 150px;
    height: 40px;
    line-height: 16px;
  }

  .delete-popup .btn-close {
    width: 15px;
    height: 15px;
  }

  .notification-wrapper.offcanvas {
    max-width: 350px;
    padding: 20px 10px;
  }

  .notification-header {
    padding-bottom: 10px;
    padding-right: 20px;
  }

  .notification-header h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .notfication-card-header {
    padding: 10px;
  }

  .notfication-card-header h5 {
    font-size: 12px;
    line-height: 16px;
  }

  .notfication-card-header .form-check-input {
    width: 15px;
    height: 15px;
  }

  .notfication-card-body {
    padding: 10px;
  }

  .notfication-card-footer {
    padding: 0 10px 5px;
  }

  .transaction-via img {
    width: 100px;
  }

  .notification-list {
    height: calc(100vh - 156px);
  }

  .notfication-card-header .form-check-input:checked[type="checkbox"] {
    width: 15px;
    height: 15px;
    background-position: center;
  }

  .notification-status {
    position: absolute;
    top: 8px;
    right: 50px;
  }

  .wallet-balance {
    padding: 25px 15px 15px;
  }

  .wallet-balance-coin {
    margin-top: 20px;
  }

  .wallet-balance-coin img {
    width: 20px;
  }

  .wallet-balance-coin p {
    font-size: 22px;
  }

  .wallet-balance-bitcoin {
    padding-top: 10px;
  }

  .wallet-balance-btn {
    gap: 20px;
    justify-content: center;
  }

  .wallet-transaction-recent {
    padding-top: 20px;
    padding-left: 15px;
  }

  .wallet-transaction-card-scroll {
    height: calc(100vh - 460px);
  }

  .wallet-transaction-card {
    padding: 0 15px 20px 15px;
  }

  .withdraw-popup-money img {
    width: 15px;
    height: 15px;
  }

  .withdraw-popup-money span {
    font-size: 24px;
    line-height: 40px;
  }

  .deposit-popup-transfer {
    padding-top: 15px;
  }

  .deposit-transfer-box-top {
    padding: 10px;
  }

  .deposit-cash-app [type="radio"]:checked+label:before,
  .deposit-cash-app [type="radio"]:not(:checked)+label:before {
    width: 15px;
    height: 15px;
  }

  .deposit-cash-app [type="radio"]:checked+label:after,
  .deposit-cash-app [type="radio"]:not(:checked)+label:after {
    top: 1px;
    left: 1px;
  }

  .deposit-cash-app [type="radio"]:checked+label,
  .deposit-cash-app [type="radio"]:not(:checked)+label {
    padding-left: 24px;
    cursor: pointer;
    line-height: 16px;
    font-size: 14px;
  }

  .deposit-cash-app img {
    width: 25px;
    height: 25px;
  }

  .deposit-card img {
    width: 35px;
    height: 35px;
  }

  .deposit-transfer-box-bottom {
    padding: 15px;
  }

  .deposit-popup-money {
    gap: 5px;
  }

  .deposit-popup-money img {
    width: 15px;
  }

  .deposit-transfer-box-bottom span {
    font-size: 16px;
    line-height: 24px;
  }

  .deposit-coin span {
    font-size: 10px;
    line-height: 14px;
  }

  .game-box {
    gap: 15px;
  }
}

@media screen and (max-width: 480px) {
  .header-menu {
    right: 0px;
  }

  .auth-box {
    margin: 0;
  }

  .user-loggedin {
    margin-right: 60px;
    max-width: 180px;
  }

  span.wallet-section {
    font-size: 12px;
  }

  span.wallet-section img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }

  .auth-page h1 {
    font-size: 28px;
  }

  .auth-page p {
    padding-bottom: 15px;
    line-height: 24px;
  }

  .login-box {
    padding-top: 15px;
    max-width: 100%;
  }

  .login-box .form-group {
    margin-bottom: 10px;
  }

  .login-box .form-label {
    margin-bottom: 5px;
  }

  .login-box .form-group .form-control {
    padding: 4px 15px;
  }

  .input-icon {
    top: 36px;
  }

  .forget-password {
    padding-top: 15px;
  }

  .l-btn.btn.btn-primary,
  .s-btn.btn.btn-primary {
    margin-top: 30px;
    padding: 10px 40px;
    border-radius: 10px;
    font-size: 16px;
  }

  .register-form .rigister-tab {
    padding-top: 15px;
  }

  .register-form .form-group {
    margin-bottom: 10px;
  }

  .signup .form-label {
    margin-bottom: 5px;
  }

  .register-form .form-group .form-control {
    padding: 4px 15px;
  }

  .already {
    padding-top: 20px;
  }

  .forget-box {
    padding-top: 15px;
  }

  .forget-form .form-group .form-control {
    padding: 4px 15px;
  }

  .send-btn,
  .send-btn:hover {
    padding: 3px 15px !important;
    font-size: 14px !important;
    top: 36px;
  }

  .forget-form .form-group {
    margin-bottom: 10px;
  }

  .forget-form .form-label {
    margin-bottom: 5px;
  }

  .footer {
    padding-top: 30px;
  }

  .top-footer {
    gap: 22px;
  }

  .top-footer img {
    max-width: 60px;
    width: 100%;
    object-fit: contain;
  }

  .main-footer .logo {
    text-align: center;
  }

  .mail-box {
    max-width: 100%;
  }

  .footer-info h6 {
    text-align: center;
  }

  .home-left h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .home-left p {
    font-size: 22px;
    padding: 15px 0;
  }

  .hero-slider-content {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .why-us-section {
    padding: 30px 0;
  }

  .our-feature h2 {
    font-size: 20px;
    line-height: 40px;
    text-align: center;
  }

  .section-title h6,
  .popular-section-title h6 {
    border-bottom: 2px solid #2e8be6;
    font-size: 16px;
  }

  .section-title h6:after,
  .popular-section-title h6:after {
    display: none;
  }

  .popular-section-title {
    top: -5px;
  }

  .popular-section-link {
    top: -3px;
  }

  .tab-section ul.nav.nav-tabs {
    justify-content: center;
    padding-top: 50px;
  }

  .popular-grid-left,
  .popular-grid-right {
    width: 45%;
  }

  .games-tab-grid ul.nav.nav-tabs {
    gap: 15px;
  }

  .games-tab-grid .nav-tabs .nav-link {
    padding: 10px;
    width: 90px;
    height: 80px;
  }

  .brand-game-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .games-brand-box img {
    height: 300px;
  }

  .game-name {
    width: 94%;
  }

  .banner-left h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .banner-left p {
    font-size: 22px;
    padding: 15px 0;
  }

  .offer-popup .modal-header {
    padding: 20px 20px 0px;
  }

  .offer-popup .modal-body {
    padding: 0px 20px 20px 20px;
  }

  .brand-info-container {
    max-height: 150px;
  }

  .dropdown-area .dropdown-menu {
    padding: 10px 0;
  }

  .legal-content-body {
    gap: 20px;
  }

  .download-games-slider .slick-dots li {
    max-width: 35px;
    height: 30px;
    margin: 0 3px;
  }

  .download-game-tiles img {
    height: 200px;
  }

  .pg-tags {
    flex-wrap: wrap;
  }

  .wallet-user-recent-game-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .wallet-user-text {
    padding-left: 15px;
  }

  .wallet-user-text h2 {
    font-size: 14px;
    line-height: 20px;
    max-width: 110px;
  }

  .wallet-user-text a {
    font-size: 12px;
  }

  .wallet-user {
    padding: 20px;
  }

  .wallet-user-newplayer {
    padding: 8px 10px 4px;
  }

  .recent-game-detail {
    max-width: calc(100% - 20px);
  }

  .user-newplayer-text h4 {
    line-height: 16px;
    font-size: 12px;
  }

  .setting-button {
    gap: 20px;
  }

  .red-btn button {
    font-size: 12px;
  }

  .transparent-btn button {
    font-size: 12px;
  }

  .setting-delete-btn button {
    font-weight: 500;
  }

  .delete-popup-content h6 {
    font-size: 18px;
    line-height: 24px;
  }

  .delete-popup-content img {
    width: 50px;
  }

  .delete-popup-content p {
    line-height: 20px;
    font-size: 14px;
  }

  .delete-popup-content {
    padding: 0 20px;
  }

  .delete-popup-footer .btn-secondary,
  .delete-popup-footer .btn-primary {
    font-size: 12px;
    padding: 10px;
    max-width: 120px;
    line-height: 18px;
  }

  .setting-detail-form input,
  .setting-detail-form input.form-control,
  .setting-detail-form input.form-control:hover,
  .setting-detail-form input.form-control:focus {
    height: 40px;
    font-size: 12px;
    line-height: 20px;
  }

  .setting-payment-info p {
    font-size: 12px;
    line-height: 20px;
  }

  .setting-cash-code,
  .setting-qr-code {
    height: 40px;
  }

  .cash-code-left img {
    height: 32px;
    width: 32px;
  }

  .qr-code-scan,
  .qr-code-scan img {
    height: 32px;
    width: 32px;
  }

  .setting-input img {
    top: 15px;
  }

  .coin-popup-content h1 {
    font-size: 16px;
    line-height: 20px;
  }

  .coin-popup-content p {
    line-height: 20px;
    font-size: 14px;
  }

  .coin-popup-content {
    padding: 15px 20px 80px;
  }

  .game-box-left img {
    width: 60px;
  }

  .coin-popup-content .game-box-right p {
    font-size: 16px;
    line-height: 20px;
  }

  .coin-game-box-img span {
    font-size: 12px;
    line-height: 20px;
  }

  .coin-popup-content h1 {
    font-size: 22px;
    line-height: 20px;
  }

  .coin-popup-content p {
    line-height: 20px;
    font-size: 14px;
  }

  .coin-popup-content {
    padding: 15px 20px 20px;
  }

  .game-box-left img {
    width: 60px;
  }

  .coin-popup-content .game-box-right p {
    font-size: 16px;
    line-height: 20px;
  }

  .coin-game-box-img span {
    font-size: 12px;
    line-height: 20px;
  }

  .game-box-btn .transparent-btn button,
  .game-box-btn .transparent-btn button:hover,
  .game-box-btn .transparent-btn button:focus {
    font-size: 12px;
    padding: 8px;
    height: 40px;
    border-radius: 10px;
    width: 100px;
    line-height: 20px;
  }

  .game-box-btn .red-btn button,
  .game-box-btn .red-btn button:hover,
  .game-box-btn .red-btn button:focus {
    font-size: 12px;
    padding: 8px;
    height: 40px;
    border-radius: 10px;
    width: 100px;
    line-height: 20px;
  }

  .coin-popup .btn-close {
    width: 12px;
    height: 12px;
  }

  .game-box-btn {
    gap: 15px;
    padding-top: 30px;
  }

  .wallet-user-level-left h3 {
    font-size: 16px;
    padding: 10px 0;
    line-height: 20px;
  }

  .wallet-user-level-left p {
    padding-bottom: 15px;
  }

  .user-newplayer-img img {
    width: 25px;
    height: 26px;
    object-fit: contain;
  }

  .user-newplayer-number img {
    width: 15px;
  }

  .user-newplayer-text p {
    font-size: 10px;
  }

  .user-newplayer-prize span {
    font-size: 12px;
  }

  .wallet-user-progress .progress {
    height: 50px !important;
  }

  .coin-popup .modal-content {
    max-width: 370px;
  }

  .my-game-page .wallet-user-recent-game-grid,
  .recharged-game-content .wallet-user-recent-game-grid,
  .redeemed-game-content .wallet-user-recent-game-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .game-info-banner img {
    height: 100px;
  }

  .recent-game_box img {
    height: 240px;
  }

  .recent-game-detail-bottom img,
  .game-request-list .recent-game-detail-bottom img {
    height: 100%;
  }

  .admin-header .user-loggedin {
    margin-right: 45px;
    max-width: 160px;
  }

  .deposit-cash-app img {
    display: none;
  }

  .hero-slider-info h6 {
    font-size: 12px;
  }

  .slick-dots li {
    margin: 0px;
  }

  .new-game-wrapper .form-group {
    margin-bottom: 10px;
  }

  .new-game-wrapper label {
    margin-bottom: 0px;
  }

  .new-game-wrapper .form-control {
    height: 38px;
    font-size: 14px;
    padding: 10px;
    font-weight: 500;
  }

  .new-game-wrapper textarea.form-control {
    height: 100px;
  }

  .brand-input .form-group {
    max-width: 100%;
  }

  .upload-game-images .form-control {
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  }

  .upload-logo-thumnail {
    gap: 15px;
  }

  .upload-game-thumnail label {
    height: 180px;
    max-width: 140px;
  }

  .upload-game-thumnail label .no-image-area {
    height: 180px;
  }

  .upload-game-thumnail label .no-image-area h6 {
    font-size: 11px;
  }

  .upload-game-thumnail label img {
    height: 150px;
  }

  .no-image-area .preview-banner {
    max-width: 98px;
    height: 80px;
  }

  .no-image-area {
    height: 195px;
  }
}